import React, { Component } from 'react';

const DEV_VERSION = 'DEV';

class Version extends Component {

    render() {

        return <div id='version'>
            <label>Version: </label>
            <span>{ process.env.REACT_APP_VERSION || DEV_VERSION }</span>
        </div>;
    }
}

export default Version;
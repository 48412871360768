import React, { Component } from "react";
import { Link } from "react-router";

import PublicationList from "./PublicationList";

class PublicationPage extends Component {
  render() {
    return (
      <div className="container content">
        <section className="row">
          <h1>Publications</h1>
          <Link to="/admin/publication/editor" className="pull-right">
            <button className="btn btn-primary">Add a new Publication</button>
          </Link>
        </section>
        <PublicationList />
      </div>
    );
  }
}

export default PublicationPage;

import React, { Component } from 'react';

import RankingTable from './RankingTable';

class UniRankingTable extends Component {

    render() {
        var uniColumns = [
            {
                title: 'Rank',
                data: 'ranking',
                responsivePriority: 2
            },
            {
                title: 'Organization',
                data: 'organisation_name',
                orderable: false,
                className: 'fomo_filterable',
                responsivePriority: 1
            },
            {
                title: 'Country',
                data: 'country_code',
                className: 'fomo_country',
                responsivePriority: 6
            },
            {
                title: 'Points',
                data: 'points',
                render: function(data, type, row, meta) {
                    // TODO: This is supposed to not work in IE?
                    return data.toFixed(1);
                },
                responsivePriority: 3
            },
            {
                title: '# of Professors',
                data: 'professor',
                responsivePriority: 5
            },
            {
                title: '# of authors',
                data: 'nrauthors',
                responsivePriority: 7
            },
            {
                title: '# of publications',
                data: 'num_of_pub',
                responsivePriority: 8
            },
            {
                title: 'Strongest researcher',
                data: function(row, type, set, meta) {
                    return row.firstname + " " + row.lastname;
                },
                responsivePriority: 4
            },
            {
                title: "Strongest researcher's share",
                data: 'topshare',
                render: function(data, type, row, meta) {
                    return parseFloat(data).toFixed(1) + "%";
                },
                responsivePriority: 6
            }

        ];

        return <RankingTable {...this.props} weightingScheme={this.props.pointsField} columns={uniColumns} limit="25" />
    }

}

export default UniRankingTable;
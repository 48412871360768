import React, { Component } from "react";

import { ajax } from "../../../network/Ajax";
import Filter from "../../../filter/Filter";
import moment from "moment/moment";

class ExportPage extends Component {
  constructor(props) {
    super(props);
    this.state = { cutoffDate: "2023-06-01", cutoffError: "" };
  }

  downloadExport(prefix, postfix) {
    const newTab = window.open("", "_blank");
    let success = (result) => {
      newTab.location = `${prefix}${result.token}${postfix}`;
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
    };

    var requestUrl = "/api/v1/account/tokens/download/request";

    ajax("POST", requestUrl, success, error);
  }

  exportPublications(query) {
    this.downloadExport("/api/v1/publications/export/", query ? `?${query}` : "");
  }

  search(query) {
    this.exportPublications(query);
  }

  changeCutoff(event) {
    this.setState({ cutoffDate: event.target.value });
  }

  exportAffiliations() {
    this.setState({ cutoffError: "" });
    if (!moment(this.state.cutoffDate, "YYYY-MM-DD", true).isValid()) {
      this.setState({
        cutoffError: "The date is not in the correct format. Use YYYY-MM-DD",
      });
      return;
    }
    this.downloadExport(
      "/api/v1/researchers/export/affiliations/",
      `/${this.state.cutoffDate}`
    );
  }

  render() {
    return (
      <div className="container content">
        <Filter
          formOptions={FormOptions}
          search={this.search.bind(this)}
          name="Export"
        />
        <div className="col-xs-12 col-md-7 well">
          <h4>Affiliations to date</h4>
          <div className="form-horizontal">
            <div
              className={
                this.state.cutoffError ? "form-group has-error" : "form-group"
              }
            >
              <label htmlFor="cutoff-date" className="col-sm-3 control-label">
                Date
              </label>
              <div className="col-sm-9 col-md-6">
                <input
                  type="text"
                  value={this.state.cutoffDate}
                  onChange={this.changeCutoff.bind(this)}
                  className="form-control"
                  id="cutoff-date"
                  name="cutoff-date"
                  placeholder="YYYY-MM-DD"
                />
                {this.state.cutoffError ? (
                  <span className="help-block has-error">
                    {this.state.cutoffError}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              <button
                className="pull-right btn btn-primary ml-15"
                onClick={this.exportAffiliations.bind(this)}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FormOptions = [
  {
    key: "publication_id",
    val: "Publication ID",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
    type: "text",
  },
  {
    key: "title",
    val: "Title",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "journal_id",
    val: "Journal",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
    type: "text",
  },
  {
    key: "journal_title",
    val: "Journal Title",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "publication_year",
    val: "Year of publication",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "vwl_weight",
    val: "VWL Weight",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "bwl_weight",
    val: "BWL Weight",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "in_process_to_publication",
    val: "In process to publication",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "publication_type_name",
    val: "Type of Publication Name",
    comp: [
      ["~*", "contains"],
      ["=", "="],
      ["!=", "!="],
    ],
  },
  {
    key: "publication_type",
    val: "Type of Publication",
    comp: [
      ["~*", "contains"],
      ["=", "="],
      ["!=", "!="],
    ],
  },
  {
    key: "hb_factor",
    val: "HB Factor",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "created",
    val: "Created",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "active",
    val: "Active",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "verified",
    val: "Verified",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "num_of_authors",
    val: "Number of Authors",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "researcher_id",
    val: "Researcher ID",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
    type: "text",
  },
  {
    key: "lastname",
    val: "Last name",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "firstname",
    val: "First name",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "field",
    val: "Field",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["BWL", "BWL"],
      ["VWL", "VWL"],
    ],
  },
  {
    key: "main_affiliation",
    val: "Main Affiliation",
    comp: [
      ["=", "="],
      ["~*", "search"],
    ],
    type: "text",
  },
  {
    key: "affiliations",
    val: "Affiliations",
    comp: [
      ["~*", "search"],
      ["=", "="],
    ],
    type: "text",
  },
  {
    key: "author_jel_codes",
    val: "Author JEL Codes",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "publication_jel_codes",
    val: "Publication JEL Codes",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "changed_by",
    val: "Changed by",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["fm_user", "User"],
      ["fm_admin", "Admin"],
    ],
  },
];

export default ExportPage;

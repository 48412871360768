import React, { Component } from "react";

import { ajax_get } from "../../../network/Ajax";
import JournalSearch from "../../../search/JournalSearch";
import WeightElement from "./WeightElement";

const MIN_YEAR = 1999;

function sanitizedWeight(weight) {
  return {
    year: weight.year,
    journal_id: weight.journal_id,
    vwl_weight: weight.vwl_weight ?? "",
    bwl_weight: weight.bwl_weight ?? "",
  };
}
class WeightEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      journalId: null,
      data: [],
      isLoading: false,
    };
  }

  setWeights = (journalId, title) => {
    this.setState({ journalId }, () => {
      if (journalId) {
        this.loadWeights();
      }
    });

    this.setState({
      journalTitle: title,
    });
  };

  updateWeights = (year, updatedWeight) => {
    this.setState((state) => ({
      data: state.data.map((currentWeight) =>
        currentWeight.year == year
          ? sanitizedWeight(updatedWeight)
          : currentWeight
      ),
    }));
  };

  loadWeights = () => {
    let success = (result) => {
      const data = JSON.parse(result[0].data) || [];
      const currentYear = new Date().getFullYear();

      const yearlyData = Object.fromEntries(
        data
          .filter(({ year }) => year >= MIN_YEAR)
          .map((weights) => [weights.year, weights])
      );
      const numberOfYears = currentYear - MIN_YEAR + 1;

      this.setState((state) => ({
        data: new Array(numberOfYears).fill().map((_, yearsBack) => {
          const year = currentYear - yearsBack;
          const existingYear = yearlyData[year] ?? {};
          return sanitizedWeight({
            year,
            vwl_weight: existingYear.vwl_weight,
            bwl_weight: existingYear.bwl_weight,
            journal_id: state.journalId,
          });
        }),
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        isLoading: false,
      }));
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    ajax_get(
      "/api/v1/weight?k=journal_id&c==&v=" + this.state.journalId,
      success,
      error
    );

    this.setState({
      isLoading: true,
    });
  };

  render() {
    return (
      <div className="container content">
        <section className="row">
          <h1 className="col-sm-offset-2 col-sm-8">Weights</h1>
          <div className="col-sm-offset-2 col-sm-8">
            <JournalSearch
              value={[this.state.journalId, this.state.journalTitle]}
              onSelect={this.setWeights}
            />
            <hr />
          </div>

          <div className="col-sm-offset-2 col-sm-8">
            {this.state.data.map((value) => {
              return (
                <WeightElement
                  key={value.year}
                  weight={value}
                  updateWeights={this.updateWeights}
                />
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

export default WeightEditor;

import React, { Component } from 'react';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tooltip';

class RankingSchemePicker extends Component {

    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        $(".weightingInfo").tooltip({
            show: { duration: 100, delay: 50 },
            tooltipClass: "weightingTooltip",
            content: function() {
                return $(this).prop("title");
            }
        })
    }

    handleChange(e) {

        this.props.setScheme(e.target.selectedIndex);
    }

    render() {

        var schemes = this.props.weightingSchemes;
        var selectedIndex = this.props.index;

        var options = options = <option>Loading...</option>;
        var description = "Please wait for the selection to load...";

        if(schemes) {
            options = schemes.map(function(value, index) {
                return <option selected={index==selectedIndex}>{value.label}</option>;
            });
            description = schemes[selectedIndex].description;
        }

        return (
            <div className="col-xs-12 col-md-5 well">
                <label>Select weighting scheme:</label>
                <form className="form-inline">
                    <select className="form-control" onChange={this.handleChange} disabled={!schemes} style={{width: '90%', display: 'inline-block'}}>
                        { options }
                    </select>
                    <span className="weightingInfo glyphicon glyphicon-info-sign" title={description}></span>
                </form>
            </div>
        );
    }

}

export default RankingSchemePicker;
import React, { Component } from "react";
import { Link } from "react-router";

/**
 * @param length: Anzahl der anzuzeigenden Listenelemente
 * @param data: Daten zum generieren der Liste
 * @param getOffset: Anzahl der Elemente die bei paginierung übersprungen wurden
 */
class PublicationListBody extends Component {
  constructor(props) {
    super(props);

    this.state = { visible: this.props.data.map(() => false) };

    this.setVisible = this.setVisible.bind(this);
  }

  setVisible(index) {
    this.setState((state) => ({
      visible: state.visible.map((itemWasVisible, itemIndex) =>
        itemIndex === index ? !itemWasVisible : itemWasVisible
      ),
    }));
  }

  render() {
    //necessary to have the outer 'this-context' at hand within map()
    var self = this;

    return (
      <tbody>
        {this.props.data.map(function (value, index) {
          var fullRow = [
            <tr
              key={value.publication_id}
              onClick={self.setVisible.bind(null, index)}
            >
              <th scope="row">{self.props.getOffset + index + 1}</th>
              <td>
                {self.props.isExternal ? (
                  <Link
                    to={"/profile/publication/editor/" + value.publication_id}
                  >
                    {value.title}
                  </Link>
                ) : (
                  <Link
                    to={"/admin/publication/editor/" + value.publication_id}
                  >
                    {value.title}
                  </Link>
                )}
              </td>
              <td>{value.publication_id}</td>
              <td>
                {self.props.isExternal ? (
                  value.journal_title + " (" + value.journal_id + ")"
                ) : (
                  <Link to={"/journal?k=journal_id&c==&v=" + value.journal_id}>
                    {value.journal_title} ({value.journal_id})
                  </Link>
                )}
              </td>
              <td>{value.publication_year}</td>
              <td>
                <ul className="list-unstyled">
                  {value.authors
                    .slice(0, 5)
                    .sort(function (a, b) {
                      return a.lastname.localeCompare(b.lastname);
                    })
                    .map(function (value) {
                      return (
                        <li key={value.researcher_id}>
                          {self.props.isExternal ? (
                            value.lastname
                          ) : (
                            <Link
                              to={
                                "/admin/researcher?k=researcher_id&c==&v=" +
                                value.researcher_id
                              }
                            >
                              {value.lastname}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </td>
              <td>{value.publication_type_name}</td>
              <td>
                <button className="btn btn-default pull-right">
                  {self.state.visible[index] ? "-" : "+"}
                </button>
              </td>
            </tr>,
          ];

          if (self.state.visible[index]) {
            fullRow.push(
              <tr key={`${value.publication_id}-details`}>
                <td colSpan="8">
                  <div className="col-xs-12">
                    <Link
                      to={
                        (self.props.isExternal
                          ? "/profile/publication/editor/"
                          : "/admin/publication/editor/") + value.publication_id
                      }
                      className="btn btn-primary pull-right"
                    >
                      Edit
                    </Link>
                  </div>

                  <dl className="dl-horizontal">
                    <dt>Year: </dt>
                    <dd>
                      {value.publication_year}
                      {value.in_process_to_publication
                        ? " | in process to publication"
                        : ""}
                    </dd>
                    <dt>Pages: </dt>
                    <dd>
                      {value.page_count} | {value.page_start} - {value.page_end}
                    </dd>
                    <dt>JEL-Codes: </dt>
                    <dd>
                      <ul className="list-unstyled">
                        {value.jel_codes.map(function (codes) {
                          return (
                            <li
                              key={`${value.publication_id}-jel-${
                                codes ? codes.jel_code : "null"
                              }`}
                            >
                              {codes ? codes.predefined_text_label : null}
                            </li>
                          );
                        })}
                      </ul>
                    </dd>
                    {self.props.isExternal ? (
                      ""
                    ) : (
                      <div>
                        <dt>Geprüft: </dt>
                        <dd>{value.verified ? "Yes" : "No"}</dd>
                      </div>
                    )}
                    <dt>Autoren: </dt>{" "}
                    <dd>
                      <ul className="list-unstyled">
                        {value.authors
                          .sort(function (a, b) {
                            return a.lastname.localeCompare(b.lastname);
                          })
                          .map(function (value) {
                            return (
                              <li key={value.researcher_id}>
                                {self.props.isExternal ? (
                                  <div>
                                    {value.firstname +
                                      " " +
                                      value.lastname +
                                      "(" +
                                      value.researcher_id +
                                      ")"}
                                  </div>
                                ) : (
                                  <Link
                                    to={
                                      "/researcher?k=researcher_id&c==&v=" +
                                      value.researcher_id
                                    }
                                  >
                                    {value.firstname +
                                      " " +
                                      value.lastname +
                                      "(" +
                                      value.researcher_id +
                                      ")"}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </dd>
                  </dl>
                </td>
              </tr>
            );
          }

          return fullRow;
        })}
      </tbody>
    );
  }
}

export default PublicationListBody;

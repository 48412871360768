import React, { Component } from "react";
import { Link } from "react-router";

class OrganizationListBody extends Component {
  constructor(props) {
    super(props);

    this.state = { visible: this.props.data.map(() => false) };

    this.setVisible = this.setVisible.bind(this);
  }

  setVisible(index) {
    this.setState((state) => ({
      visible: state.visible.map((previouslyVisible, visibilityIndex) =>
        visibilityIndex == index ? !previouslyVisible : previouslyVisible
      ),
    }));
  }

  render() {
    //necessary to have the outer 'this-context' at hand within map()
    var self = this;

    return (
      <tbody>
        {this.props.data.map(function (value, index) {
          var fullRow = [
            <tr
              key={value.organization_id}
              onClick={self.setVisible.bind(null, index)}
              className="pointer"
            >
              <th scope="row">{self.props.getOffset + index + 1}</th>
              <td>
                <Link
                  to={"/admin/organization/editor/" + value.organization_id}
                >
                  {value.name}
                </Link>
              </td>
              <td>
                {value.country_code} | {value.zipcode}
              </td>
              <td>{value.verified ? "Yes" : "No"}</td>
              <td>{value.organization_id}</td>
              <td>
                <button className="btn btn-default pull-right">
                  {self.state.visible[index] ? "-" : "+"}
                </button>
              </td>
            </tr>,
          ];
          if (self.state.visible[index]) {
            fullRow.push(
              <tr className="" key={`${value.organization_id}_details`}>
                <td colSpan="6">
                  <div className="col-xs-12">
                    <Link
                      to={"/admin/organization/editor/" + value.organization_id}
                      className="btn btn-primary pull-right"
                    >
                      Edit
                    </Link>
                  </div>
                  <dl className="dl-horizontal col-xs-10">
                    <dt>Description: </dt>
                    <dd>{value.description}</dd>
                  </dl>
                </td>
              </tr>
            );
          }

          return fullRow;
        })}
      </tbody>
    );
  }
}

export default OrganizationListBody;

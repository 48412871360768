import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import Jquery from "jquery";

import { ajax, ajax_get } from "../../../../network/Ajax";
import Message from "../../../../message/Message";
import { Spinner } from "../../../../spinner/Spinner";
import AddJelCodes from "../../../../formcomponents/AddJelCodes";
import AddAuthors from "../../../../formcomponents/AddAuthors";
import JournalSearch from "../../../../search/JournalSearch";

function sanitizedData(data = {}) {
  const init = data.publication_id
    ? { publication_id: data.publication_id }
    : {};
  return {
    ...init,
    // ...data,
    title: data.title || "",
    journal_id: data.journal_id || "",
    journal_title: data.journal_title || "",
    jel_codes: data.jel_codes || [],
    authors: data.authors || [],
    researcher_ids: data.researcher_ids || [],
    publication_year: data.publication_year || "",
    publication_type: data.publication_type || "",
    volume: data.volume || "",
    journal_nr: data.journal_nr || "",
    page_start: data.page_start || "",
    page_end: data.page_end || "",
    in_process_to_publication: data.in_process_to_publication || false,
  };
}
/**
 * @param publicationType: Typ der Publikation
 * @param predefined: Predeined Elemente für <select>
 */
class ProfilePublicationEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: sanitizedData(),
      showActive: false,
      isLoading: false,
      shouldFetch: false,
    };
  }

  componentDidMount() {
    if (this.props.params.id) {
      this.loadData();
    }
  }

  componentDidUpdate() {
    if (this.state.shouldFetch) {
      this.loadData();
    }
  }

  loadData = () => {
    let success = (result) => {
      if (!result[0].data) {
        this.setState({
          response_code: "204",
          response_text:
            "No publication with the ID " +
            this.props.params.id +
            " could be found.",
          data: sanitizedData(),
          isLoading: false,
          shouldFetch: false,
        });
      } else {
        var data = JSON.parse(result[0].data)[0];

        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          data: sanitizedData(data),
          showActive: !data.active,
          isLoading: false,
          shouldFetch: false,
        });
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
        shouldFetch: false,
      });
    };

    var requestUrl =
      "/api/v1/publications/byresearcher?k=publication_id&c==&v=" +
      this.props.params.id;

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  };

  handleInputChange = (event) => {
    var target = event.target;
    const updatedValue =
      target.type === "checkbox" ? target.checked : target.value;

    this.setState((state) => ({
      data: { ...state.data, [target.name]: updatedValue },
    }));
  };

  setJournal = (journalId, title) => {
    this.setState((state) => ({
      data: { ...state.data, journal_id: journalId, journal_title: title },
    }));
  };

  addJelCode = (id, name) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: [
          ...state.data.jel_codes,
          { jel_code: id, predefined_text_label: name },
        ],
      },
    }));
  };

  removeJelCode = (jel_code_to_remove) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: state.data.jel_codes.filter((jel_code_data) => {
          return jel_code_data.jel_code != jel_code_to_remove;
        }),
      },
    }));
  };

  addAuthor = (author) => {
    this.setState((state) => ({
      data: { ...state.data, authors: [...state.data.authors, { ...author }] },
    }));
  };

  removeAuthor = (researcher_id) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        authors: state.data.authors.filter(
          (author) => author.researcher_id != researcher_id,
        ),
      },
    }));
  };

  savePublication = () => {
    var publication = { ...this.state.data };

    let success = (result) => {
      if (result[0].response_code != "0" || !result[0].data) {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
        });
      } else {
        if (!this.props.params.id) {
          var resultData = JSON.parse(result[0].data)[0];

          this.setState({
            data: sanitizedData(resultData),
            isLoading: false,
          });

          this.context.router.push(
            `/profile/publication/editor/${resultData.publication_id}`,
          );
        } else {
          this.context.router.push(
            `/profile/publication?n=publication_id&c==&v=${
              JSON.parse(result[0].data)[0].publication_id
            }`,
          );
          this.setState({
            isLoading: false,
          });
        }
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: true,
      });
    };

    publication.page_count =
      this.state.data.page_end - publication.page_start || null;
    publication.researcher_ids = [];

    for (var i = 0; i < this.state.data.authors.length; i++) {
      publication.researcher_ids.push(publication.authors[i].researcher_id);
    }

    if (
      publication.title &&
      publication.publication_year &&
      publication.publication_type &&
      publication.journal_id
    ) {
      if (!this.props.params.id) {
        ajax(
          "POST",
          "/api/v1/publications/byresearcher",
          success,
          error,
          publication,
        );
      } else {
        ajax(
          "PUT",
          "/api/v1/publications/byresearcher",
          success,
          error,
          this.state.data,
        );
      }

      this.setState({
        isLoading: true,
      });
    } else {
      this.setState({
        response_code: "400",
        response_text: "Please set all required fields!",
      });

      Jquery("html, body").animate(
        {
          scrollTop: 0,
        },
        500,
      );
    }
  };

  render() {
    var title = "Add a new publication";
    var domain = "forschungsmonitoring.org";
    var data = this.state.data;

    if (this.props.params.id) title = "Edit publication";

    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <h1 className="col-sm-offset-3 col-sm-9">{title}</h1>
        </section>

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <form className="form-horizontal">
              <div className="form-group">
                <label htmlFor="titel" className="col-sm-3 control-label">
                  Title <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <input
                    value={data.title}
                    onChange={this.handleInputChange}
                    className="form-control"
                    id="titel"
                    name="title"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="publication_year"
                  className="col-sm-3 control-label"
                >
                  Publication Year <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <div className="col-xs-3 no-pl">
                    <input
                      value={data.publication_year}
                      onChange={this.handleInputChange}
                      className="form-control"
                      id="publication_year"
                      name="publication_year"
                      placeholder="Year"
                      type="number"
                    />
                  </div>
                </div>
                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <hr />
                </div>
              </div>

              <AddAuthors
                data={this.state.data}
                add={this.addAuthor}
                remove={this.removeAuthor}
                isExternal={true}
              />

              <div className="form-group">
                <label
                  htmlFor="publication_year"
                  className="col-sm-3 control-label"
                >
                  Journal <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <JournalSearch
                    value={[data.journal_id, data.journal_title]}
                    onSelect={this.setJournal}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="journalIssue"
                  className="col-sm-3 control-label"
                >
                  Volume | Number
                </label>
                <div id="journalIssue" className="col-sm-9 col-md-6">
                  <div className="col-xs-3 no-pl">
                    <input
                      value={data.volume}
                      onChange={this.handleInputChange}
                      className="form-control"
                      name="volume"
                      placeholder="Volume"
                    />
                  </div>
                  <div className="col-xs-3">
                    <input
                      value={data.journal_nr}
                      onChange={this.handleInputChange}
                      className="form-control"
                      name="journal_nr"
                      placeholder="Number"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="pages" className="col-sm-3 control-label">
                  Pages
                </label>
                <div id="pages" className="col-sm-9 col-md-6">
                  <div className="col-xs-3 no-pl">
                    <input
                      value={data.page_start}
                      onChange={this.handleInputChange}
                      name="page_start"
                      type="number"
                      className="form-control"
                      placeholder="Start"
                    />
                  </div>
                  <div className="col-xs-3">
                    <input
                      value={data.page_end}
                      onChange={this.handleInputChange}
                      name="page_end"
                      type="number"
                      className="form-control"
                      placeholder="End"
                    />
                  </div>
                </div>
                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <hr />
                </div>
              </div>

              <AddJelCodes
                data={this.state.data}
                predefined={this.props.predefined}
                add={this.addJelCode}
                remove={this.removeJelCode}
                isExternal={true}
              />

              <div className="form-group">
                <label
                  htmlFor="in_process_to_publication"
                  className="col-sm-3 control-label"
                >
                  In process to publication
                </label>
                <div
                  id="in_process_to_publication"
                  className="col-sm-9 col-md-6"
                >
                  <label className="checkbox-inline">
                    <input
                      checked={this.state.data.in_process_to_publication}
                      onChange={this.handleInputChange}
                      type="checkbox"
                      name="in_process_to_publication"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label
                  htmlFor="inputArtPublikation"
                  className="col-sm-3 control-label"
                >
                  Type of publication <span className="red">*</span>
                </label>
                <div id="inputArtPublikation" className="col-sm-9 col-md-6">
                  <select
                    className="form-control"
                    name="publication_type"
                    value={data.publication_type}
                    onChange={this.handleInputChange}
                  >
                    <option disabled value="">
                      {" "}
                      -- Select an Option --{" "}
                    </option>
                    {this.props.publicationType.map(function (value) {
                      return (
                        <option
                          key={value.publication_type_id}
                          value={value.publication_type_id}
                        >
                          {value.publication_type}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <hr />
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                <hr />
              </div>

              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                {this.props.params.id && (
                  <a href={"mailto:kontakt" + "@" + domain}>
                    Request for deletion
                  </a>
                )}
                <div className="pull-right">
                  <Link to="/profile/publication" className="btn btn-default">
                    Dismiss
                  </Link>
                  <button
                    className="btn btn-primary ml-15"
                    onClick={this.savePublication}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

ProfilePublicationEditor.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ProfilePublicationEditor;

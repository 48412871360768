import React, { Component } from "react";
import { Link } from "react-router";

import OrganizationList from "./OrganizationList";

class OrganizationPage extends Component {
  render() {
    return (
      <div className="container content">
        <section className="row">
          <h1>Organizations</h1>
          <Link to="/admin/organization/editor" className="pull-right">
            <button className="btn btn-primary">Add new organization</button>
          </Link>
        </section>
        <OrganizationList />
      </div>
    );
  }
}

export default OrganizationPage;

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";

import Jquery from "jquery";

import { ajax, ajax_get } from "../../../../network/Ajax";
import Message from "../../../../message/Message";
import { Spinner } from "../../../../spinner/Spinner";
import ExternalFormFields from "../../../../formcomponents/ExternalFormFields";

/**
 * @param predefined: Predeined Elemente für <select>
 */
class ResearcherEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: {
        firstname: "",
        lastname: "",
        affiliations: [],
      },
      delete: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.params.id) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.id != prevProps.params.id) {
      this.loadData();
    }
  }

  loadData = () => {
    var success = (result) => {
      if (!result[0].data) {
        this.setState({
          response_code: "204",
          response_text:
            "No researcher with the ID " +
            this.props.params.id +
            " could be found.",
          data: [],
          isLoading: false,
        });
      } else {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          data: JSON.parse(result[0].data)[0] || [],
          currentEmail: JSON.parse(result[0].data)[0].email,
          isLoading: false,
        });
      }
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    var requestUrl =
      "/api/v1/researchers?k=researcher_id&c==&v=" + this.props.params.id;

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  };

  toggleDelete = () => {
    this.setState({
      delete: !this.state.delete,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const updatedValue =
      target.type === "checkbox" ? target.checked : target.value;

    this.setState((state) => ({
      data: {
        ...state.data,
        [target.name]: updatedValue,
      },
    }));
  };

  handleRadioChange = (event) => {
    var target = event.target;
    var value = target.value;
    if (value && typeof value === "string") {
      if (value === "true") value = true;
      if (value === "false") value = false;
    }

    this.setState((state) => ({
      data: {
        ...state.data,
        [target.name]: value,
      },
    }));
  };

  setLocation = (key, organization_id, organization_name) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        [key]: organization_id,
        [`${key}_name`]: organization_name,
      },
    }));
  };

  addAffiliation = (affiliation) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        affiliations: [...state.data.affiliations, affiliation],
      },
    }));
  };

  updateAffiliation = (updatedAffiliation) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        affiliations: state.data.affiliations
          .filter((affiliation) => !!affiliation)
          .map((affiliation) =>
            affiliation.affiliation_id == updatedAffiliation.affiliation_id
              ? updatedAffiliation
              : affiliation
          ),
      },
    }));
  };

  removeAffiliation = (affiliation_id) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        affiliations: state.data.affiliations.filter(
          (affiliation) => affiliation.affiliation_id != affiliation_id
        ),
      },
    }));
  };

  addJelCode = (code, label) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: [
          ...state.data.jel_codes,
          { jel_code: code, predefined_text_label: label },
        ],
      },
    }));
  };

  removeJelCode = (jel_code) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: state.data.jel_codes.filter(
          (jel) => jel && jel.jel_code != jel_code
        ),
      },
    }));
  };

  saveResearcher = () => {
    var researcher = { ...this.state.data };

    var success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        if (!this.props.params.id) {
          var resultData = JSON.parse(result[0].data)[0];
          researcher = resultData;
          if (!researcher.affiliations) {
            researcher.affiliations = [];
          }
          if (!researcher.jel_codes) {
            researcher.jel_codes = [];
          }
          this.setState({
            isLoading: false,
            data: resultData,
          });

          this.context.router.push(
            `/admin/researcher/editor/${resultData.researcher_id}`
          );
        } else {
          this.context.router.push(
            `/admin/researcher?n=researcher_id&c==&v=${
              JSON.parse(result[0].data)[0].researcher_id
            }`
          );
        }
      }
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    if (
      researcher.title &&
      researcher.firstname &&
      researcher.lastname &&
      researcher.field &&
      (researcher.ranking_relevant == true ||
        researcher.ranking_relevant == false)
    ) {
      if (
        researcher.email &&
        this.state.currentEmail &&
        researcher.email != this.state.currentEmail
      ) {
        researcher.emailChanged = true;
        researcher.oldEmail = this.state.currentEmail;
      } else {
        researcher.emailChanged = false;
      }

      ajax("POST", "/api/v1/researchers", success, error, researcher);

      this.setState({
        isLoading: true,
        response_code: "0",
        response_text: "",
      });
    } else {
      this.setState({
        response_code: "400",
        response_text: "Please set all required fields!",
      });

      Jquery("html, body").animate(
        {
          scrollTop: 0,
        },
        500
      );
    }
  };

  deleteResearcher = () => {
    var success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        this.context.router.push("/admin/researcher");
      }
    };

    var error = (xhr, status, err) => {
      this.setState({
        isLoading: false,
      });
      console.error(status, err.toString(), xhr.toString);
    };

    ajax("DELETE", "/api/v1/researchers", success, error, this.state.data);

    this.setState({
      isLoading: true,
    });
  };

  render() {
    var title = "Add new researcher profile";
    var data = this.state.data;

    if (this.props.params.id) title = "Edit researcher profile";

    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <h1 className="col-sm-offset-3 col-sm-9">{title}</h1>
        </section>

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <form className="form-horizontal">
              <ExternalFormFields
                data={this.state.data}
                handleInputChange={this.handleInputChange}
                setLocation={this.setLocation}
                predefined={this.props.predefined}
                addAffiliation={this.addAffiliation}
                updateAffiliation={this.updateAffiliation}
                removeAffiliation={this.removeAffiliation}
                addJelCode={this.addJelCode}
                removeJelCode={this.removeJelCode}
              />

              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                <hr />
              </div>

              <h3 className="col-sm-offset-3 col-sm-9 mb-15">
                Internal Information
              </h3>

              <div className="form-group">
                <label
                  htmlFor="inputRelevance"
                  className="col-sm-3 control-label"
                >
                  Ranking relevant <span className="red">*</span>
                </label>
                <div id="inputRelevance" className="col-sm-9 col-md-6">
                  <label className="radio-inline">
                    <input
                      type="radio"
                      value={true}
                      name="ranking_relevant"
                      checked={this.state.data.ranking_relevant == true}
                      onChange={this.handleRadioChange}
                    />{" "}
                    Yes
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      value={false}
                      name="ranking_relevant"
                      checked={this.state.data.ranking_relevant == false}
                      onChange={this.handleRadioChange}
                    />{" "}
                    No
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label
                  htmlFor="ranking_justification"
                  className="col-sm-3 control-label"
                >
                  Ranking justification
                </label>
                <div className="col-sm-9 col-md-6">
                  <textarea
                    value={data.ranking_justification}
                    onChange={this.handleInputChange}
                    type="text"
                    rows="5"
                    className="form-control"
                    id="ranking_justification"
                    name="ranking_justification"
                    placeholder="Ranking justification"
                  ></textarea>
                </div>
              </div>

              <div className="form-group">
                <label
                  htmlFor="comments_internal"
                  className="col-sm-3 control-label"
                >
                  Internal comment
                </label>
                <div className="col-sm-9 col-md-6">
                  <textarea
                    value={data.comments_internal}
                    onChange={this.handleInputChange}
                    type="text"
                    className="form-control"
                    rows="5"
                    id="comments_internal"
                    name="comments_internal"
                    placeholder="Internal Comment"
                  ></textarea>
                </div>
              </div>

              <div className="form-group">
                <div className="checkbox col-sm-offset-3 col-sm-9">
                  <label>
                    <input
                      type="checkbox"
                      checked={data.robinson}
                      onChange={this.handleInputChange}
                      name="robinson"
                    />{" "}
                    <strong>Robinson</strong>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div className="checkbox col-sm-offset-3 col-sm-9">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.data.verified}
                      onChange={this.handleInputChange}
                      name="verified"
                    />{" "}
                    <strong>Verified</strong>
                  </label>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                <hr />
              </div>

              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                {this.props.params.id && (
                  <button
                    className="btn btn-danger"
                    onClick={this.toggleDelete}
                  >
                    Delete
                  </button>
                )}
                <div className="pull-right">
                  <Link to="/admin/researcher" className="btn btn-default">
                    Dismiss
                  </Link>
                  <button
                    className="btn btn-primary ml-15"
                    onClick={this.saveResearcher}
                  >
                    Save
                  </button>
                </div>
              </div>

              {this.state.delete && (
                <div className="col-xs-12 col-sm-offset-3 col-sm-9 col-md-6 mt-30">
                  <div className="well">
                    <h4>
                      Are you sure you want to delete this researcher's profile?
                    </h4>
                    <button
                      className="btn btn-primary"
                      onClick={this.toggleDelete}
                    >
                      No
                    </button>
                    <button
                      className="btn btn-danger pull-right"
                      onClick={this.deleteResearcher}
                    >
                      Delete permanently
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

ResearcherEditor.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ResearcherEditor;

import React, { Component } from 'react';

/**
 * @param message: Text welcher dem Nutzer angezeigt wird
 * @param code: HTTP Status Code
 */
class Message extends Component {

    render() {

        return <div className="row">
            <div className="alert alert-danger" role="alert">
                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <span className="sr-only">Error: </span>
                <span>&nbsp; {this.props.message} ({this.props.code})</span>
            </div>
        </div>

    }
}

export default Message;
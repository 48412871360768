import React, { Component } from 'react';

import Jquery from 'jquery';

/**
 * @param setOffset(): Eine Funktion welcher der Offset Wert für das nächste Query übergeben wird
 * @param getOffset: Ein Wert welcher angibt wieviele Einträge in der DB in der letzten Anfrage überspringen wurden
 * @param limit: Die Anzahl der Resultate pro Seite
 * @param data: Die Daten der aktuellen Seite
 * @param anchor: Id eines Elements zu welchem beim Wechseln einer Seite gescrollt wird
 *
 */
class Pagination extends Component {

    constructor() {
        super();

        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    prevPage() {

        var p = this.props;
        var offset = p.getOffset - p.limit;
        if (offset < 0) offset = 0;

        p.setOffset(offset);

        if (this.props.anchor) {
            Jquery('html, body').animate({
                scrollTop: Jquery('#' + this.props.anchor).offset().top
            }, 500);
        }
    }

    nextPage() {

        var p = this.props;
        var offset = p.getOffset + p.limit;

        p.setOffset(offset);

        if (this.props.anchor) {
            Jquery('html, body').animate({
                scrollTop: Jquery('#' + this.props.anchor).offset().top
            }, 500);
        }
    }

    render() {

        var hasBackArrow = this.props.getOffset != 0;
        var hasForwardArrow = (this.props.limit == this.props.data.length);

        return <section>
            {this.props.data[0] && <nav className="row">
                <ul className="pager">
                    {hasBackArrow && <li onClick={this.prevPage} className="pull-left pointer"><span>zurück</span></li>}
                    {hasForwardArrow && <li onClick={this.nextPage} className="pull-right pointer"><span>weiter</span></li>}
                </ul>
            </nav>}
        </section>

    }

}

export default Pagination;
import React, { Component } from "react";
import { Link } from "react-router";
import Moment from "moment";

import PublicationList from "../publication/PublicationList";

function uniqueAffiliations(affiliations) {
  return affiliations.reduce(
    (current_affiliations, next_affiliation) => {
      if (!next_affiliation) {
        return current_affiliations;
      }
      if (
        current_affiliations.affiliation_ids.has(
          next_affiliation.organization_id
        )
      ) {
        return current_affiliations;
      }
      return {
        affiliation_ids: new Set([
          ...current_affiliations.affiliation_ids,
          next_affiliation.organization_id,
        ]),
        affiliations: [...current_affiliations.affiliations, next_affiliation],
      };
    },
    { affiliation_ids: new Set(), affiliations: [] }
  ).affiliations;
}
/**
 * @param length: Anzahl der anzuzeigenden Listenelemente
 * @param data: Daten zum generieren der Liste
 * @param getOffset: Anzahl der Elemente die bei paginierung übersprungen wurden
 */
class ResearcherListBody extends Component {
  constructor(props) {
    super(props);

    this.state = { visible: this.props.data.map(() => false) };

    this.setVisible = this.setVisible.bind(this);
  }

  setVisible(index) {
    this.setState((state) => ({
      visible: state.visible.map((previousVisibility, visibilityIndex) =>
        visibilityIndex == index ? !previousVisibility : previousVisibility
      ),
    }));
  }

  render() {
    //necessary to have the outer 'this-context' at hand within map()
    var self = this;

    return (
      <tbody>
        {this.props.data.map(function (value, index) {
          var fullRow = [
            <tr
              key={value.researcher_id}
              onClick={self.setVisible.bind(null, index)}
              className="pointer"
            >
              <th scope="row">{self.props.getOffset + index + 1}</th>
              <td>
                <Link to={"/admin/researcher/editor/" + value.researcher_id}>
                  {value.title_name} {value.firstname} {value.lastname}
                </Link>
              </td>
              <td>
                <ul className="list-unstyled">
                  {uniqueAffiliations(value.affiliations)
                    .slice(0, 5)
                    .map(function (value) {
                      if (value) {
                        return (
                          <li key={value.organization_id}>
                            <Link
                              to={
                                "/admin/organization?k=organization_id&c==&v=" +
                                value.organization_id
                              }
                            >
                              {value.organization_name}
                            </Link>
                          </li>
                        );
                      }
                    })}
                </ul>
              </td>
              <td>{value.email}</td>
              <td>{value.field}</td>
              <td>{value.ranking_relevant ? "Yes" : "No"}</td>
              <td>{value.verified ? "Yes" : "No"}</td>
              <td>{value.researcher_id}</td>
              <td>
                <button className="btn btn-default pull-right">
                  {self.state.visible[index] ? "-" : "+"}
                </button>
              </td>
            </tr>,
          ];

          if (self.state.visible[index]) {
            fullRow.push(
              <tr key={`${value.researcher_id}_details`}>
                <td colSpan="9">
                  <div className="col-xs-12">
                    <Link
                      to={"/admin/researcher/editor/" + value.researcher_id}
                      className="btn btn-primary pull-right"
                    >
                      Bearbeiten
                    </Link>
                  </div>
                  <dl className="dl-horizontal">
                    <dt>Gender: </dt>
                    <dd>{value.gender}</dd>
                    <dt>Birthdate: </dt>{" "}
                    <dd>
                      {value.birthdate &&
                        Moment(value.birthdate).format("Do MMMM YYYY")}
                    </dd>
                    <dt>Website: </dt>{" "}
                    <dd>
                      <a href={value.website}>{value.website}</a>
                    </dd>
                    <dt>CV: </dt>{" "}
                    <dd>
                      <a href={value.cv_url}>{value.cv_url}</a>
                    </dd>
                    <dt>Habilitation: </dt>{" "}
                    <dd>
                      {value.habilitation_location_name} -{" "}
                      {value.habilitation_year}
                    </dd>
                    <dt>PHD: </dt>{" "}
                    <dd>
                      {value.phd_location_name} - {value.phd_year}
                    </dd>
                    <dt>JEL Codes: </dt>{" "}
                    <dd>
                      <ul className="list-unstyled">
                        {value.jel_codes.map(function (value, index) {
                          return (
                            <li key={value ? value.jel_code : `null-${index}`}>
                              {value ? value.jel_code_name : null}
                            </li>
                          );
                        })}
                      </ul>
                    </dd>
                    <dt>ORCID iD: </dt>
                    <dd>{value.orcid}</dd>
                    <dt>Comment: </dt> <dd>{value.comments}</dd>
                    <hr />
                    <dt>Affiliations: </dt>{" "}
                    <dd>
                      <ul className="list-unstyled">
                        {value.affiliations
                          .sort(function (a, b) {
                            //to_date's can also contain the string 'Today'
                            var startA = a.to_date;
                            var startB = b.to_date;

                            if (startA == "Today") {
                              startA = new Date();
                            }
                            if (startB == "Today") {
                              startB = new Date();
                            }

                            return new Date(startB) - new Date(startA);
                          })
                          .map(function (value) {
                            if (value) {
                              return (
                                <li className="mb-7" key={value.affiliation_id}>
                                  <Link
                                    to={
                                      "/admin/organization?k=organization_id&c==&v=" +
                                      value.organization_id
                                    }
                                  >
                                    {value.organization_name}
                                  </Link>
                                  {value.employment && (
                                    <span> | {value.employment} %</span>
                                  )}
                                  <br />
                                  {value.from_date && (
                                    <span>
                                      {Moment(value.from_date).format(
                                        "Do MMMM YYYY"
                                      )}
                                    </span>
                                  )}
                                  {value.to_date &&
                                    value.to_date != "Today" && (
                                      <span>
                                        {" "}
                                        -{" "}
                                        {Moment(value.to_date).format(
                                          "Do MMMM YYYY"
                                        )}
                                      </span>
                                    )}
                                  <br />
                                  {value.title_name && (
                                    <span>{value.title_name} | </span>
                                  )}
                                  {<span>{value.description}</span>}
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </dd>
                    <hr />
                    <dt>Ranking justification: </dt>{" "}
                    <dd>{value.ranking_justification}</dd>
                    <dt>Internal Comment: </dt>{" "}
                    <dd>{value.comments_internal}</dd>
                    <dt>Robinson: </dt> <dd>{value.robinson ? "Yes" : "No"}</dd>
                    <dt>Created at: </dt>{" "}
                    <dd>{Moment(value.created).format("Do MMMM YYYY")}</dd>
                    <hr />
                  </dl>
                  <div className="col-xs-12 pt-15 grey-bg mb-30">
                    <div className="col-xs-12 ">
                      <PublicationList
                        researcherIdFilter={value.researcher_id}
                        anchor={value.researcher_id}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          }

          return fullRow;
        })}
      </tbody>
    );
  }
}

export default ResearcherListBody;

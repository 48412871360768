import React, { Component } from "react";

import { ajax_get } from "../network/Ajax";

/**
 * @param onSelect: Funktion die Ausgelöst wird sobald der Nutzer ein Suchresultat gewählt hat,
 * */
class AuthorSearch extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.timer = null;
  }

  handleInputChange = (event) => {
    var target = event.target;

    this.setState({ [target.name]: target.value });
    this.search();
  };

  search = () => {
    var self = this;

    function success(result) {
      self.setState({
        options: JSON.parse(result[0].data) || [],
      });
    }

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      var uri =
        "/api/v1/researchers/search?k1=firstname&c1=~*&v1=" +
        encodeURIComponent(self.state.firstname) +
        "&k2=lastname&c2=~*&v2=" +
        encodeURIComponent(self.state.lastname) +
        "&limit=10";

      ajax_get(uri, success);
    }, 100);
  };

  select = (event) => {
    var index = event.nativeEvent.target.selectedIndex;

    this.setState({
      options: [],
    });

    this.props.onSelect(this.state.options[index]);
  };

  close = () => {
    this.setState({
      options: [],
    });
  };

  render() {
    var self = this;
    var selector = (
      <select className="form-control" size="7" onChange={self.select}>
        <optgroup label="Bitte wählen Sie einen Autor:">
          {self.state.options.map(function (value, index) {
            return (
              <option value={value.researcher_id}>
                {value.firstname +
                  " " +
                  value.lastname +
                  " (" +
                  value.affiliation +
                  ")"}
              </option>
            );
          })}
        </optgroup>
      </select>
    );

    return (
      <div>
        <div className="col-xs-12 col-sm-6  no-pl has-feedback">
          <input
            value={this.state.firstname}
            onChange={this.handleInputChange}
            onClick={this.close}
            autoComplete="off"
            className="form-control col-sm-6"
            name="firstname"
            placeholder="First Name"
          />
          {this.state.researcher_id ? (
            <span
              className="glyphicon glyphicon-ok form-control-feedback"
              aria-hidden="true"
            ></span>
          ) : null}
        </div>
        <div className="col-xs-12 col-sm-6  no-pr has-feedback">
          <input
            value={this.state.lastname}
            onChange={this.handleInputChange}
            onClick={this.close}
            autoComplete="off"
            className="form-control col-sm-6"
            name="lastname"
            placeholder="Last Name"
          />
          {this.state.researcher_id ? (
            <span
              className="glyphicon glyphicon-ok form-control-feedback"
              aria-hidden="true"
            ></span>
          ) : null}
        </div>
        <div className="col-xs-12 no-pl">
          {this.state.options[0] ? selector : null}
        </div>
      </div>
    );
  }
}

const initialState = {
  options: [],
  firstname: "",
  lastname: "",
  researcher_id: "",
};

export default AuthorSearch;

import React, { Component } from "react";

/**
 * @param formOptions: Optionen welche der Filter annehmen kann
 * @param index: Index des Filterelements im Filter
 * @param selected: Filter welcher für dieses Element selektiert ist
 * @param keyChange(): Ändert das Key Element des Filters
 * @param compChange(): Ändert das Comparator Element des Filters
 * @param valChange(): Ändert das Value Element des Filters
 * @param search(): Löst die Suchfunktion aus
 * @param remove(): Entfernt das Filterelement
 * */

class FilterForm extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.search();
    }
  }

  render() {
    var p = this.props;

    return (
      <div className="form-inline">
        <select
          className="form-control"
          value={p.selected.key}
          onChange={p.keyChange}
          onKeyDown={this.handleKeyDown}
        >
          {p.formOptions.map(function (value) {
            return (
              <option key={value.key} value={value.key}>
                {value.val}
              </option>
            );
          })}
        </select>
        <select
          className="form-control"
          value={p.selected.comp}
          onChange={p.compChange}
          onKeyDown={this.handleKeyDown}
        >
          {p.selected.obj.comp.map(function (value) {
            return (
              <option key={value[0]} value={value[0]}>
                {value[1]}
              </option>
            );
          })}
        </select>
        {p.selected.obj.opt ? (
          <select
            className="form-control"
            value={p.selected.val}
            onChange={p.valChange}
            onKeyDown={this.handleKeyDown}
          >
            {p.selected.obj.opt.map(function (value) {
              return <option value={value[0]}>{value[1]}</option>;
            })}
          </select>
        ) : (
          <input
            type={p.selected.obj.type}
            className="form-control"
            value={p.selected.val}
            onChange={p.valChange}
            onKeyDown={this.handleKeyDown}
          />
        )}
        <button className="btn btn-default" type="button" onClick={p.remove}>
          x
        </button>
      </div>
    );
  }
}

export default FilterForm;

import jQuery from 'jquery';
import { jwt_isSet, jwt_get, jwt_delete } from '../security/JWTStore';

let ajax = (method, url, success, error, data, cache) => {

    jQuery.ajax({
        headers: jwt_isSet() ? { 'Authorization' : 'Bearer ' + jwt_get() } : {},
        type: method,
        url:  url,
        dataType: 'json',
        data: data ? data : {},
        cache: cache ? cache : false,
        success: success,
        error: function(xhr, status, err) {

            if(xhr.status === 401 || xhr.status === 403) {
                jwt_delete();
            }

            if (error) {
                error(xhr, status, err);
            }
        }
    });
}

function ajax_get(url, success, error, data, cache) {

    ajax('GET', url, success, error, data, cache);
}

export { ajax, ajax_get };
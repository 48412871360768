/**
 * Created by thoenis on 02.07.2018.
 */
import React, { Component } from 'react';

class Contact extends Component {

    render() {

        return <div className = "container mt-30 content ">
            <div className = "row mt-30">
                <div className = "col-xs-12 col-sm-6 col-md-offset-1 col-md-6">
                    <h4>Allgemeine Anfragen/General Inquiries</h4>
                    Anfragen zu Profilen und sonstigen Inhalten beantworten wir Ihnen gerne unter:<br/>
                    We will gladly answer inquiries about profiles and other content at:<br/>
                    <a href="mailto:kontakt@forschungsmonitoring.org">kontakt@forschungsmonitoring.org</a>
                </div>
            </div>
            <div className = "row mt-30">
                <div className = "col-xs-12 col-sm-6 col-md-offset-1 col-md-4">
                    <h4>Projektverantwortlicher KOF:</h4>
                    <b>Prof. Dr. Jan-Egbert Sturm</b><br/>
                    Professur f. Wirtschaftsforschung<br/>
                    Leonhardstrasse 21, 8092 Zürich<br/>
                    Schweiz<br/>
                    Tel. +41 44 632 50 01<br/>
                    Mail sturm<span style={{display: "none"}}>null</span>@kof.ethz.ch
                </div>
                <div className = "col-xs-12 col-sm-6 col-md-offset-1 col-md-4">
                    <h4>Projektverantwortlicher DICE:</h4>
                    <b>Prof. Dr. Justus Haucap</b><br/>
                    Düsseldorf Institute for Competition Economics (DICE)<br/>
                    Heinrich-Heine-Universität Düsseldorf<br/>
                    Universitätsstr. 1, 40225 Düsseldorf<br/>
                    Deutschland<br/>
                    Tel. +49 211 - 811 5494<br/>
                    Mail haucap<span style={{display: "none"}}>null</span>@dice.hhu.de
                </div>
            </div>
        </div>;
    }
}

export default Contact;
import React, { Component } from "react";
import { Link } from "react-router";

import ResearcherList from "./ResearcherList";

class ResearcherPage extends Component {
  render() {
    return (
      <div className="container content">
        <section className="row">
          <h1>Researcher</h1>
          <Link to="/admin/researcher/editor" className="pull-right">
            <button className="btn btn-primary">Add a new Researcher</button>
          </Link>
        </section>
        <ResearcherList />
      </div>
    );
  }
}

export default ResearcherPage;

import React, { Component } from "react";
import Jquery from "jquery";

import FilterForm from "./FilterForm";

function replaceAtIndex(index, newValue) {
  return (oldValue, valueIndex) => (valueIndex == index ? newValue : oldValue);
}

/**
 * @param name: Name des Filterelements
 * @param formOptions: Objekt mit allen Optionen für den Filter
 * @param researcherIdFilter: Id eines Forschers für den voreingestellten Filter der Publikationen
 * @param limit: Die Limit Einstellung (Anzahl Suchresultate) des Filters
 * @param setLimit: Ändern des Limits
 */
class Filter extends Component {
  constructor(props) {
    super(props);

    var selected = [
      {
        obj: this.props.formOptions[0],
        key: this.props.formOptions[0].key,
        comp: this.props.formOptions[0].comp[0][0],
        val: "",
      },
    ];

    if (this.props.name == "Export") selected = [];

    if (this.props.researcherIdFilter) {
      selected = [
        {
          obj: this.props.formOptions[4],
          key: this.props.formOptions[4].key,
          comp: this.props.formOptions[4].comp[0][0],
          val: this.props.researcherIdFilter,
        },
      ];
    }

    this.state = {
      formOptions: this.props.formOptions,
      selected: selected,
      exportMessage: false,
    };

    this.search = this.search.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.keyChange = this.keyChange.bind(this);
    this.compChange = this.compChange.bind(this);
    this.valChange = this.valChange.bind(this);
    this.addSelector = this.addSelector.bind(this);
    this.removeSelector = this.removeSelector.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }

  componentDidMount() {
    if (this.props.researcherIdFilter) {
      this.search();
    }
  }

  search() {
    var query = [];
    this.state.selected.forEach(function (entry, index) {
      if (entry.val) {
        query.push({ name: "k" + index, value: entry.key });
        query.push({ name: "c" + index, value: entry.comp });
        query.push({ name: "v" + index, value: entry.val });
      }
    });

    this.props.search(
      Jquery.param(query) +
        (this.props.limit ? "&limit=" + this.props.limit : "")
    );
  }

  exportCSV() {
    var query = [];
    this.state.selected.forEach(function (entry, index) {
      if (entry.key == "author_names") {
        entry.key = "lastname";
        entry.comp = "~*";
      } else if (entry.key == "researcher_ids") {
        entry.key = "researcher_id";
        entry.comp = "~*";
      } else if (entry.key == "affiliation_names") {
        entry.key = "organization_name";
        entry.comp = "~*";
      }

      query.push({ name: "k" + index, value: entry.key });
      query.push({ name: "c" + index, value: entry.comp });
      query.push({ name: "v" + index, value: entry.val });
    });

    this.props.export(
      Jquery.param(query) +
        (this.props.limit ? "&limit=" + this.props.limit : "")
    );

    this.setState({
      exportMessage: true,
    });
  }

  keyChange(index, e) {
    var key = e.target.value;
    var obj = this.state.formOptions.find((elm) => elm.key == key);
    this.setState((state) => {
      const newItem = {
        obj: obj,
        key: key,
        comp: obj.comp[0][0],
        val: obj.opt ? obj.opt[0][0] : "",
      };
      return {
        selected: state.selected.map(replaceAtIndex(index, newItem)),
      };
    });
  }

  compChange(index, e) {
    const value = e.target.value;
    this.setState((state) => {
      const newItem = {
        ...state.selected[index],
        comp: value,
      };
      return {
        selected: state.selected.map(replaceAtIndex(index, newItem)),
      };
    });
  }

  valChange(index, e) {
    const value = e.target.value;
    this.setState((state) => {
      const newItem = {
        ...state.selected[index],
        val: value,
      };
      return {
        selected: state.selected.map(replaceAtIndex(index, newItem)),
      };
    });
  }

  addSelector() {
    this.setState((state, props) => ({
      selected: [
        ...state.selected,
        {
          obj: props.formOptions[0],
          key: props.formOptions[0].key,
          comp: props.formOptions[0].comp[0][0],
          val: "",
        },
      ],
    }));
  }

  removeSelector(index) {
    this.setState((state) => ({
      selected: state.selected.filter((_item, itemIndex) => itemIndex != index),
    }));
  }

  changeLimit(e) {
    this.props.setLimit(parseInt(e.target.value, 10));
  }

  render() {
    var self = this;
    var isExport = this.props.name == "Export";

    return (
      <div className="col-xs-12 col-md-7 well">
        <h4>{this.props.name} search:</h4>
        <div>
          {this.state.selected.map(function (value, index) {
            return (
              <FilterForm
                key={index}
                formOptions={self.state.formOptions}
                selected={value}
                keyChange={self.keyChange.bind(this, index)}
                compChange={self.compChange.bind(this, index)}
                valChange={self.valChange.bind(this, index)}
                index={index}
                remove={self.removeSelector.bind(this, index)}
                search={self.search}
              />
            );
          })}
        </div>

        <div className="form-inline">
          <div className="form-group col-xs-9 no-pl">
            <button className="btn btn-default" onClick={this.addSelector}>
              +
            </button>
            {!isExport && (
              <div className="pull-right">
                <label htmlFor="limit">Results&nbsp;</label>
                <select
                  id="limit"
                  className="form-control"
                  onChange={this.changeLimit}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="">all</option>
                </select>
              </div>
            )}
          </div>
          <div className="pull-right">
            {!isExport && !this.props.isProfile && (
              <button className="btn btn-default" onClick={this.exportCSV}>
                Export
              </button>
            )}
            <button className="btn btn-primary ml-15" onClick={this.search}>
              {isExport ? "Export" : "Search"}
            </button>
          </div>
        </div>

        {(this.state.exportMessage || isExport) && (
          <div className="col-xs-12">
            <p className="pt-15 red">
              Exporting data can take up to 10 minutes. <br />
              Please don't click the "Export" button again until the download in
              the new tab has completed.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Filter;

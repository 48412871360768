import React, { Component } from 'react';

import Notice from '../notice/Notice';

class JELGeneralNotice extends Component {

    render() {

        return <Notice>
            The general JEL category that showed up with highest frequency here was used to serve as
            illustration of your research area for the Handelsblatt-VWL-2017 Ranking.
            In the future we plan to offer an additional option that allows to select the
            general JEL category which is the main focus of your research in addition to the more detailed JEL codes.
        </Notice>
    }
}

export default JELGeneralNotice;
import React, { Component } from 'react';
import Version from './Version';

class Footer extends Component {

    render() {

        const domain = "forschungsmonitoring.org";
        const devWarning = (window.location.toString().indexOf("dev") >= 0 || window.location.toString().indexOf("127.0.0.1") >= 0) ? <div style={{fontSize: 30, fontWeight: 'bold', color: 'red', textAlign: 'center'}}>Development version!!!</div> : null;

        return (
            <footer className="footer">
                { devWarning }
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-30">
                                <h4>Herausgeber:</h4>

                                <p>Prof. Dr. Jan-Egbert Sturm & Prof. Dr. Justus Haucap</p>

                                <h4>Redaktion:</h4>

                                <p>Lamar Crombach</p>
                                <a href={"mailto:" + "kontakt" + "@" + domain}>{"kontakt" + "@" + domain}</a>
                            </div>
                            { this.props.loggedIn ? <Version /> : null }
                        </div>
                        <div className = "col-lg-6">
                            <div className = "mt-30">
                                <a href="/datenschutz">Datenschutzerklärung</a>
                            </div>
                            <div className = "mt-30">
                                <a href="/kontakt">Kontakt</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
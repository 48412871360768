import React, { Component } from 'react';
import $ from 'jquery';

import RankingTable from './RankingTable';

class ResearcherRankingTable extends Component {

    render() {

        var na = "<span class='rankingNA'>N/A</span>";
        var bins = this.props.percentBins;

        var researcherColumns = [
            {
                title: 'Rank',
                data: 'ranking',
                responsivePriority: 2
            },
            {
                title: 'in top %',
                data: function(row, type, set, meta) {
                    var totalRecords = meta.settings.json.total;
                    var truePercent = Math.ceil(100*row['ranking']/totalRecords);
                    var i = 0;
                    while(truePercent > bins[i]) {
                        i++;
                    }
                    return bins[i] + "%";
                },
                responsivePriority: 4
            },
            {
                title: 'First name',
                data: 'firstname',
                className: 'fomo_filterable',
                responsivePriority: 4

            },
            {
                title: 'Last name',
                data: 'lastname',
                className: 'fomo_filterable',
                responsivePriority: 1
            },
            {   // TODO: This is an array variable. Custom renderer? Default seems to handle fine.
                title: 'Organization(s)',
                data: 'organizations[, ].name',
                render: function(data, type, row, meta) {
                    // TODO: Those could be links
                    return data == "" ? na : data;
                },
                className: 'fomo_filterable',
                responsivePriority: 5
            },
            {
                title: 'Country',
                data: 'organizations[].country_code',
                render: function(data, type, row, meta) {
                    if(data != "") {
                        data = data.filter((x) => x !== null);
                        data = $.unique(data);
                        data.sort();
                        return data.join(", ");
                    } else {
                        return na;
                    }
                },
                className: 'fomo_country',
                responsivePriority: 6
            },
            {
                title: 'Age',
                data: 'age',
                //defaultContent: 'N/A' Doesn't work since age is present as ""
                render: function(data, type, row, meta) {
                    return data == "" ? na : data;
                },
                className: 'fomo_age',
                responsivePriority: 6
            },
            { // TODO: Custom renderer
                // JEL Codes are objects with keys predefined_text_id and predefined_text_label
                title: 'JEL Code(s)',
                data: 'jel_codes',
                orderable: false,
                render: function(data, type, row, meta) {
                    if(data.length == 0) {
                        return na;
                    } else {
                        var codes = [];
                        for(var i = 0; i < data.length; i++) {
                            codes.push("<abbr title='" + data[i].predefined_text_label + "'>" + data[i].predefined_text_id + "</abbr>");
                        }
                        return codes.join(", ");
                    }
                },
                className: 'fomo_filterable',
                responsivePriority: 7
            },
            {
                title: '# of publications',
                data: 'num_of_pub',
                responsivePriority: 8
            },
            {
                title: 'Points',
                data: 'points',
                render: function(data, type, row, meta) {
                    // TODO: This is supposed to not work in IE?
                    return data.toFixed(2);
                },
                responsivePriority: 3
            }
        ];

        var disclaimerAmount = null;
        if(this.props.ranking == "authors" || this.props.ranking == "young") {
            disclaimerAmount = 100;
        } else {
            disclaimerAmount = 250;
        }

        return <RankingTable 
        	{...this.props}
        	weightingScheme={this.props.pointsField}
        	columns={researcherColumns}
        	disclaimerAmount={disclaimerAmount}/>
    }
}

export default ResearcherRankingTable;
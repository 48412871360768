import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";

import { ajax, ajax_get } from "../../../../network/Ajax";
import Message from "../../../../message/Message";
import { Spinner } from "../../../../spinner/Spinner";

function sanitizedData(data = {}) {
  const initial = data.journal_id ? { journal_id: data.journal_id } : {};
  return {
    ...initial,
    title: data.title || "",
    issn: data.issn || "",
    ranking_relevant: data.ranking_relevant || false,
    vwl_ranking_relevant: data.vwl_ranking_relevant || false,
    bwl_ranking_relevant: data.bwl_ranking_relevant || false,
    verified: data.verified || false,
  };
}

class JournalEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: sanitizedData(),
      delete: false,
      isLoading: false,
      prevId: props.params.id,
      shouldFetch: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.params.id != state.prevId) {
      return {
        prevId: props.params.id,
        data: sanitizedData(),
        shouldFetch: true,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.params.id) {
      this.loadData();
    }
  }

  componentDidUpdate() {
    if (this.state.shouldFetch) {
      this.loadData();
    }
  }

  loadData = () => {
    let success = (result) => {
      if (!result[0].data) {
        this.setState({
          response_code: "204",
          response_text:
            "No Journal with the ID " +
            this.props.params.id +
            " could be found.",
          data: sanitizedData(),
          isLoading: false,
          shouldFetch: false,
        });
      } else {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          data: sanitizedData(JSON.parse(result[0].data)[0]),
          isLoading: false,
          shouldFetch: false,
        });
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);

      this.setState({
        isLoading: false,
        shouldFetch: false,
      });
    };

    var requestUrl =
      "/api/v1/journals?na=active&ca==&va=true&k=journal_id&c==&v=" +
      this.props.params.id;

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const updatedValue =
      target.type === "checkbox" ? target.checked : target.value;

    this.setState((state) => ({
      data: { ...state.data, [target.name]: updatedValue },
    }));
  };

  saveJournal = () => {
    let success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
        });
      } else {
        this.context.router.push(
          `/admin/journal?n=journal_id&c==&v=${
            JSON.parse(result[0].data)[0].journal_id
          }`
        );
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
    };

    ajax("POST", "/api/v1/journals", success, error, this.state.data);
  };

  toggleDelete = () => {
    this.setState((state) => ({
      delete: !state.delete,
    }));
  };

  deleteJournal = () => {
    let success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        this.context.router.push("/admin/journal");
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    ajax(
      "DELETE",
      "/api/v1/journals/" + this.props.params.id,
      success,
      error,
      this.state.data
    );

    this.setState({
      isLoading: true,
    });
  };

  render() {
    var title = "Add a new Journal";
    if (this.props.params.id) title = "Edit Journal";

    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <h1 className="col-sm-offset-3 col-sm-9">{title}</h1>
        </section>

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <form className="form-horizontal">
              <div className="form-group">
                <label htmlFor="titel" className="col-sm-3 control-label">
                  Title
                </label>
                <div className="col-sm-9 col-md-6">
                  <input
                    value={this.state.data.title}
                    onChange={this.handleInputChange}
                    className="form-control"
                    id="titel"
                    name="title"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="issn" className="col-sm-3 control-label">
                  ISSN
                </label>
                <div className="col-sm-9 col-md-6">
                  <input
                    value={this.state.data.issn}
                    onChange={this.handleInputChange}
                    type="text"
                    className="form-control"
                    id="issn"
                    name="issn"
                    placeholder="ISSN"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="rankinRelevance"
                  className="col-sm-3 control-label"
                >
                  Ranking relevant
                </label>
                <div id="rankinRelevance" className="col-sm-9 col-md-6">
                  <label className="checkbox-inline">
                    <input
                      checked={this.state.data.ranking_relevant}
                      onChange={this.handleInputChange}
                      type="checkbox"
                      name="ranking_relevant"
                      id="ranking_relevant"
                    />
                    Yes
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="inputRelevance"
                  className="col-sm-3 control-label"
                >
                  Relevant for
                </label>
                <div id="inputRelevance" className="col-sm-9 col-md-6">
                  <label className="checkbox-inline">
                    <input
                      checked={this.state.data.vwl_ranking_relevant}
                      onChange={this.handleInputChange}
                      type="checkbox"
                      name="vwl_ranking_relevant"
                      id="vwl_ranking_relevant"
                    />
                    VWL
                  </label>
                  <label className="checkbox-inline">
                    <input
                      checked={this.state.data.bwl_ranking_relevant}
                      onChange={this.handleInputChange}
                      type="checkbox"
                      name="bwl_ranking_relevant"
                      id="bwl_ranking_relevant"
                    />
                    BWL
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="checkbox col-sm-offset-3 col-sm-9">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.data.verified}
                      onChange={this.handleInputChange}
                      name="verified"
                    />
                    <strong>Verified</strong>
                  </label>
                </div>
              </div>
            </form>

            <div className="row mt-30">
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                <hr />
              </div>
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                {this.props.params.id && (
                  <button
                    className="btn btn-danger"
                    onClick={this.toggleDelete}
                  >
                    Delete
                  </button>
                )}
                <div className="pull-right">
                  <Link to="/admin/journal" className="btn btn-default">
                    Dismiss
                  </Link>
                  <button
                    className="btn btn-primary ml-15"
                    onClick={this.saveJournal}
                  >
                    Save
                  </button>
                </div>
              </div>
              {this.state.delete && (
                <div className="col-xs-12 col-sm-offset-3 col-sm-9 col-md-6 mt-30">
                  <div className="well">
                    <h4>Are you sure to delete this journal permanently?</h4>
                    <button
                      className="btn btn-primary"
                      onClick={this.toggleDelete}
                    >
                      No
                    </button>
                    <button
                      className="btn btn-danger pull-right"
                      onClick={this.deleteJournal}
                    >
                      Delete permanently
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

JournalEditor.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default JournalEditor;

import React, { Component } from 'react';

/**
 * @param message: Text welcher dem Nutzer angezeigt wird
 * @param code: HTTP Status Code
 */

class Notice extends Component {

    render() {

        return <div style={{maxWidth: 500}}>
            <div className="alert alert-warning" role="alert">
                <span className="glyphicon glyphicon-warning-sign" aria-hidden="true"></span>
                &nbsp;&nbsp;
                {this.props.children}
            </div>
        </div>
    }
}

export default Notice;
import React, { Component } from "react";
import window from "window-or-global";

import { ajax, ajax_get } from "../../../network/Ajax";

import PublicationListBody from "./PublicationListBody";
import Filter from "../../../filter/Filter";
import Message from "../../../message/Message";
import Pagination from "../../../filter/Pagination";
import { Spinner } from "../../../spinner/Spinner";

/**
 * @param anchor: Verwenden eines html anchor tags (id)
 * @param researcherIdFilter: Flag welche angibt ob Publikation
 *        innerhalb der Forscher Liste verwendet wird. In diesem Fall
 *        sollte der Query String aus dem Url nicht gelesen werden.
 *
 */
class PublicationList extends Component {
  constructor(props) {
    super(props);

    if (this.props.anchor) {
      Anchor = this.props.anchor;
    }

    this.state = {
      response_code: "0",
      data: [],
      showDetails: {},
      filter: [],
      offset: 0,
      limit: 20,
      isLoading: false,
    };

    this.exportCSV = this.exportCSV.bind(this);
    this.search = this.search.bind(this);
    this.export = this.export.bind(this);
    this.setOffset = this.setOffset.bind(this);
    this.setLimit = this.setLimit.bind(this);
    this.sortData = this.sortData.bind(this);
  }

  componentDidMount() {
    if (window.location.search && !this.props.researcherIdFilter) {
      this.loadData();
    }
  }

  loadData() {
    var success = (result) => {
      var data = JSON.parse(result[0].data) || [];

      this.setState({
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        data: data,
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    var requestUrl =
      ApiUrl +
      "?na=active&ca==&va=true" +
      (this.state.query
        ? this.state.query
        : "&" + window.location.search.substring(1)) +
      (this.state.offset != 0
        ? "&offset=" + this.state.offset
        : "" + "&orderby=-publication_year");

    this.setState({
      isLoading: true,
    });

    ajax_get(requestUrl, success, error);
  }

  exportCSV() {
    var self = this;

    var success = (result) => {
      var downloadUrl =
        ApiUrl + "/export/webview/" + result.token + "?" + self.state.query;

      newTab.location = downloadUrl;
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
    };

    var newTab = window.open("", "_blank");

    var requestUrl = "/api/v1/account/tokens/download/request";

    ajax("POST", requestUrl, success, error);
  }

  search(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.loadData()
    );
  }

  export(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.exportCSV()
    );
  }

  setOffset(offset) {
    this.setState(
      {
        offset,
      },
      () => this.loadData()
    );
  }

  setLimit(limit) {
    this.setState({
      limit: limit,
    });
  }

  sortData(key) {
    function sortPublication(a, b) {
      if (Array.isArray(a[key]) && Array.isArray(b[key])) {
        var textA = a[key].sort().toString().toUpperCase();
        var textB = b[key].sort().toString().toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        var textA = a[key].toUpperCase();
        var textB = b[key].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
      return b[key] - a[key];
    }
    this.setState((state) => ({ data: [...state.data].sort(sortPublication) }));
  }

  render() {
    //necessary to have the outer 'this-context' at hand within map()
    var self = this;

    return (
      <div id={Anchor}>
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <Filter
            formOptions={FormOptions}
            search={this.search}
            export={this.export}
            limit={this.state.limit}
            setLimit={this.setLimit}
            name="Publikationen"
            researcherIdFilter={this.props.researcherIdFilter}
          />
        </section>
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(null, "title")}
                    >
                      Title&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(null, "publication_id")}
                    >
                      ID&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(null, "journal_title")}
                    >
                      Journal (ID)&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(null, "publication_year")}
                    >
                      Year&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(null, "author_names")}
                    >
                      Authors&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(null, "publication_type")}
                    >
                      Type&#x25BC;
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <PublicationListBody
                  data={self.state.data}
                  getOffset={this.state.offset}
                />
              </table>
            </div>
          </section>
        )}
        <Pagination
          data={this.state.data}
          limit={this.state.limit}
          getOffset={this.state.offset}
          setOffset={this.setOffset}
          anchor={Anchor}
        />
      </div>
    );
  }
}

const ApiUrl = "/api/v1/publications";
var Anchor = "publication-list";
const FormOptions = [
  {
    key: "title",
    val: "Title",
    comp: [
      ["~*", "contains"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "publication_year",
    val: "Year of publication",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "in_process_to_publication",
    val: "In process to publication",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "author_names",
    val: "Author Name",
    comp: [
      ["@>", "(co-)author"],
      ["=", "sole author"],
    ],
    type: "text",
  },
  {
    key: "researcher_ids",
    val: "Author ID",
    comp: [
      ["@>", "(co-)author"],
      ["=", "solel author"],
    ],
    type: "text",
  },
  {
    key: "publication_type_name",
    val: "Type of Publication",
    comp: [
      ["~*", "contains"],
      ["=", "="],
      ["!=", "!="],
    ],
  },
  {
    key: "publication_id",
    val: "ID",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
    type: "text",
  },
  {
    key: "verified",
    val: "Verified",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "journal_title",
    val: "Journal Title",
    comp: [
      ["=", "="],
      ["~*", "contains"],
    ],
    type: "text",
  },
  {
    key: "journal_id",
    val: "Journal ID",
    comp: [["=", "="]],
    type: "text",
  },
];

export default PublicationList;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Spinner from 'spin';

class MySpinner extends Component {

    componentDidMount() {

        new Spinner({ scale: this.props.scale || 1.0 }).spin(ReactDOM.findDOMNode(this.refs.target));
    }

    render() {

        return <div className='spinner' ref='target'></div>;
    }

}

export { MySpinner as Spinner };
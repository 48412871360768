import React, { Component } from "react";
import { Link } from "react-router";

/**
 * @param length: Anzahl der anzuzeigenden Listenelemente
 * @param data: Daten zum generieren der Liste
 * @param getOffset: Anzahl der Elemente die bei paginierung übersprungen wurden
 */

class JournalListBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.data.map(() => false),
    };
  }

  setVisible = (index) => {
    this.setState((state) => ({
      visible: state.visible.map((itemWasVisible, itemIndex) =>
        itemIndex === index ? !itemWasVisible : itemWasVisible
      ),
    }));
  };

  render() {
    //necessary to have the outer 'this-context' at hand within map()
    var self = this;

    return (
      <tbody>
        {this.props.data.map(function (value, index) {
          var fullRow = [
            <tr
              key={value.journal_id}
              onClick={self.setVisible.bind(null, index)}
              className="pointer"
            >
              <td scope="row">{self.props.getOffset + index + 1}</td>
              <td>
                <Link to={`/admin/journal/editor/${value.journal_id}`}>
                  {value.title_decorated}
                </Link>
              </td>

              <td>{value.bwl_ranking_relevant ? "Yes" : "No"}</td>
              <td>{value.vwl_ranking_relevant ? "Yes" : "No"}</td>
              <td>{value.weight}</td>
              <td>{value.verified ? "Yes" : "No"}</td>
              <td>{value.journal_id}</td>
              <td>
                <button className="btn btn-default pull-right jj">
                  {self.state.visible[index] ? "-" : "+"}
                </button>
              </td>
            </tr>,
          ];

          if (self.state.visible[index]) {
            fullRow.push(
              <tr key={`${value.journal_id}-details`}>
                <td colSpan="9">
                  <div className="col-xs-12">
                    <Link
                      to={"/admin/journal/editor/" + value.journal_id}
                      className="btn btn-primary pull-right"
                    >
                      Edit
                    </Link>
                  </div>
                  <dl className="dl-horizontal col-xs-10">
                    <dt>ISSN: </dt>
                    <dd>{value.issn}</dd>
                    <dt>Rankingrelevant: </dt>
                    <dd>{value.ranking_relevant ? "Yes" : "No"}</dd>
                  </dl>
                </td>
              </tr>
            );
          }

          return fullRow;
        })}
      </tbody>
    );
  }
}

export default JournalListBody;

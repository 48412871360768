import React, { Component } from "react";
import window from "window-or-global";

import { ajax, ajax_get } from "../../../network/Ajax";

import OrganizationListBody from "./OrganizationListBody";
import Filter from "../../../filter/Filter";
import Message from "../../../message/Message";
import Pagination from "../../../filter/Pagination";
import { Spinner } from "../../../spinner/Spinner";

class OrganizationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      organizations: [],
      showDetails: {},
      filter: [],
      formOptions: FormOptions,
      query: null,
      offset: 0,
      limit: 20,
      isLoading: false,
      exportAll: false,
    };

    this.loadOrganizations = this.loadOrganizations.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.search = this.search.bind(this);
    this.export = this.export.bind(this);
    this.setOffset = this.setOffset.bind(this);
    this.setLimit = this.setLimit.bind(this);
    this.showDetailView = this.showDetailView.bind(this);
  }

  componentDidMount() {
    if (window.location.search) {
      this.loadOrganizations();
    }
  }

  loadOrganizations() {
    var success = (result) => {
      this.setState({
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        organizations: JSON.parse(result[0].data) || [],
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    /**
     * Der mittlere String reduziert die Daten auf aktive Organisationen
     * */
    var requestUrl =
      ApiUrl +
      "?na=active&ca==&va=true" +
      (this.state.query
        ? this.state.query
        : "&" + window.location.search.substring(1)) +
      (this.state.offset != 0 ? "&offset=" + this.state.offset : "");

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  }

  exportCSV() {
    var self = this;

    var success = (result) => {
      var downloadUrl =
        ApiUrl + "/export/webview/" + result.token + "?" + self.state.query;

      newTab.location = downloadUrl;
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
    };

    var newTab = window.open("", "_blank");

    var requestUrl = "/api/v1/account/tokens/download/request";

    ajax("POST", requestUrl, success, error);
  }

  search(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.loadOrganizations()
    );
  }

  export(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.exportCSV()
    );
  }

  setOffset(offset) {
    this.setState(
      {
        offset,
      },
      () => this.loadOrganizations()
    );
  }

  setLimit(limit) {
    this.setState({
      limit: limit,
    });
  }

  showDetailView(index) {
    this.setState((state) => ({
      organizations: state.organizations.map(
        (organization, organizationIndex) => ({
          ...organization,
          visible:
            organizationIndex == index
              ? !organization.visible
              : organization.visible,
        })
      ),
    }));
  }

  render() {
    return (
      <div id={anchor}>
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <Filter
            formOptions={FormOptions}
            search={this.search}
            export={this.export}
            limit={this.state.limit}
            setLimit={this.setLimit}
            name="Organizations"
          />
        </section>
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Country | Zip Code</th>
                  <th>Verified</th>
                  <th>ID</th>
                  <th></th>
                </tr>
              </thead>
              <OrganizationListBody
                data={this.state.organizations}
                getOffset={this.state.offset}
              />
            </table>
          </section>
        )}
        <Pagination
          data={this.state.organizations}
          limit={this.state.limit}
          getOffset={this.state.offset}
          setOffset={this.setOffset}
          anchor={anchor}
        />
      </div>
    );
  }
}

const ApiUrl = "/api/v1/organizations";
const anchor = "organization-list";
const FormOptions = [
  {
    key: "name",
    val: "Name",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "zipcode",
    val: "Zip Code",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
    type: "text",
  },
  {
    key: "country_code",
    val: "Country Code",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "verified",
    val: "Verified",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "organization_id",
    val: "ID",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
  },
];

export default OrganizationList;

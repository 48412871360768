import React from "react";
import ReactDOM from "react-dom";
import {
  browserHistory,
  Router,
  Route,
  IndexRoute,
  Redirect,
} from "react-router";

import "./css/bootstrap-3.3.7-dist/css/bootstrap.min.css";
import "./css/timepicker.css";
import "./css/datatables.min.css";
import "./css/style.css";
//import './css/jquery-ui.min.css';
//import './css/jquery-ui.theme.min.css';

import MonitoringApp from "./components/view/MonitoringApp";

import JournalPage from "./components/view/main/journal/JournalPage";
import JournalEditor from "./components/view/main/journal/editor/JournalEditor";

import OrganizationPage from "./components/view/main/organization/OrganizationPage";
import OrganizationEditor from "./components/view/main/organization/editor/OrganizationEditor";

import PublicationPage from "./components/view/main/publication/PublicationPage";
import PublicationEditor from "./components/view/main/publication/editor/PublicationEditor";

import ResearcherPage from "./components/view/main/researcher/ResearcherPage";
import ResearcherEditor from "./components/view/main/researcher/editor/ResearcherEditor";

import PredefinedEditor from "./components/view/main/predefined/PredefinedEditor";

import WeightEditor from "./components/view/main/weight/WeightEditor";

import ExportPage from "./components/view/main/export/ExportPage";

import ProfilePage from "./components/view/main/profile/ProfilePage";
import ProfileEditor from "./components/view/main/profile/editor/ProfileEditor";

import ProfilePublicationPage from "./components/view/main/profilepublication/ProfilePublicationPage";
import ProfilePublicationEditor from "./components/view/main/profilepublication/editor/ProfilePublicationEditor";

import PublicPages from "./components/view/PublicPages";
import Home from "./components/view/main/public/Home";
import Login from "./components/view/main/public/Login";
import PasswordRecovery from "./components/view/main/public/PasswordRecovery";
import SetPassword from "./components/view/main/public/SetPassword";

import Ranking from "./components/view/main/public/ranking/Ranking";

import DataDisclaimer from "./components/view/main/public/DataDisclaimer";
import Contact from "./components/view/main/public/Contact";
import { requireAuth } from "./components/security/Authentication";

const routes = (
  <Route path="/">
    /*Routes für welche User eingeloggt sein müssen*/
    <Route path="admin" component={MonitoringApp} onEnter={requireAuth}>
      /*Routes für internes Datenmanagement*/
      <Route path="journal" onEnter={requireAuth} component={JournalPage} />
      <Route path="journal/editor" component={JournalEditor} />
      <Route path="journal/editor/:id" component={JournalEditor} />
      <Route
        path="organization"
        onEnter={requireAuth}
        component={OrganizationPage}
      />
      <Route path="organization/editor" component={OrganizationEditor} />
      <Route path="organization/editor/:id" component={OrganizationEditor} />
      <Route
        path="publication"
        onEnter={requireAuth}
        component={PublicationPage}
      />
      <Route path="publication/editor" component={PublicationEditor} />
      <Route path="publication/editor/:id" component={PublicationEditor} />
      <Route
        path="researcher"
        onEnter={requireAuth}
        component={ResearcherPage}
      />
      <Route path="researcher/editor" component={ResearcherEditor} />
      <Route path="researcher/editor/:id" component={ResearcherEditor} />
      <Route
        path="settings"
        onEnter={requireAuth}
        component={PredefinedEditor}
      />
      <Route path="weight" onEnter={requireAuth} component={WeightEditor} />
      <Route path="export" component={ExportPage} />
    </Route>
    /*Routes für Forscher Accounts*/
    <Route path="profile" component={MonitoringApp}>
      <IndexRoute onEnter={requireAuth} component={ProfilePage} />
      <Route path="editor" component={ProfileEditor} />
      <Route path="editor/:id" component={ProfileEditor} />

      <Route
        path="publication"
        onEnter={requireAuth}
        component={ProfilePublicationPage}
      />
      <Route path="publication/editor" component={ProfilePublicationEditor} />
      <Route
        path="publication/editor/:id"
        component={ProfilePublicationEditor}
      />
    </Route>
    <Route component={PublicPages}>
      <Route path="home" component={Home} />
      <Route path="login" component={Login} />
      <Route path="password/recovery" component={PasswordRecovery} />
      <Route path="password/set" component={SetPassword} />

      <Redirect from="ranking" to="/ranking/vwl/authors" />
      <Route path="ranking">
        // TODO: is this possible with route params?
        <Redirect from="authors" to="/ranking/vwl/author" />
        <Redirect from="young" to="/ranking/vwl/young" />
        <Redirect from="lifetime" to="/ranking/vwl/lifetime" />
        <Redirect from="university" to="/ranking/vwl/university" />
        <Redirect from="institute" to="/ranking/vwl/institute" />
        <Route path=":type/:category" component={Ranking} />
      </Route>

      <Route path="datenschutz" component={DataDisclaimer} />
      <Route path="kontakt" component={Contact} />

      <IndexRoute component={Home} />
      <Route path="*" component={Home} />
    </Route>
  </Route>
);

if (
  window.location.toString().indexOf("dev") >= 0 ||
  window.location.toString().indexOf("127.0.0.1") >= 0 ||
  window.location.toString().indexOf("localhost") >= 0
) {
  document.title = "🐸 Froschungsmonitoring 🐸";
}

ReactDOM.render(
  <Router history={browserHistory}>{routes}</Router>,
  document.getElementById("app")
);

import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";

import { ajax } from "../../../network/Ajax";

class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = { password: "", password_retype: "", message: "" };
  }

  isPasswordValid(password, password_retype) {
    return !_.isEmpty(password) && password === password_retype;
  }

  submit = (e) => {
    e.preventDefault();

    this.setState({ message: "" });

    let success = () => {
      this.context.router.push("/login");
    };

    let error = (response) => {
      response.responseText = JSON.parse(response.responseText);

      this.setState({
        message:
          "We are sorry, something went wrong. Please try again in a moment. (" +
          response.status +
          " - " +
          response.responseText.text +
          ")",
      });
    };

    if (this.isPasswordValid(this.state.password, this.state.password_retype)) {
      ajax("PUT", "/api/v1/account/user/password", success, error, {
        token: this.props.location.query.t,
        pwd: this.state.password,
      });
    } else {
      this.setState({
        message: "Please make sure to confirm your password correctly.",
        password: "",
        password_retype: "",
      });
    }
  };

  change = (key, e) => {
    this.setState(_.set({}, key, e.target.value));
  };

  render() {
    return (
      <div className="container content">
        {this.state.message && (
          <div className="alert alert-danger mt-30">
            <h4 className="text-center">{this.state.message}</h4>
          </div>
        )}

        <div className="well mt-30">
          <div className="row">
            <h4 className="col-sm-offset-4 col-sm-5 mb-30">
              Set a new Password:
            </h4>
          </div>

          <form onSubmit={this.submit} className="form-horizontal">
            <div className="form-group">
              <label
                htmlFor="password"
                className="col-sm-2 col-sm-offset-2 control-label"
              >
                New Password
              </label>
              <div className="col-sm-5">
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={_.partial(this.change, "password")}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="password_retype"
                className="col-sm-2 col-sm-offset-2 control-label"
              >
                Retype Password
              </label>
              <div className="col-sm-5">
                <input
                  id="password_retype"
                  type="password"
                  placeholder="Retype Password"
                  value={this.state.password_retype}
                  onChange={_.partial(this.change, "password_retype")}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-4 col-sm-5">
                <input
                  type="submit"
                  value="Set Password"
                  className="btn btn-default pull-right"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SetPassword.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SetPassword;

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Router, Link } from "react-router";
import window from "window-or-global";

import { auth_logout, auth_loggedIn } from "../../security/Authentication";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { menu: true };
  }

  updateDimensions() {
    if (window.innerWidth > 768) {
      this.setState({
        menu: true,
      });
    } else {
      this.setState({
        menu: false,
      });
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  toggleMenu = () => this.setState({ menu: !this.state.menu });

  logout() {
    let transitionToLogout = () => {
      this.context.router.push("/login");
    };

    auth_logout(transitionToLogout);
  }

  render() {
    const path = window.location.pathname;
    let menu = null;

    if (!this.props.public) {
      if (path.substring(0, 8) === "/profile") {
        menu = (
          <ul className="nav navbar-nav">
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/profile/publication">Publications</Link>
            </li>
            <li>
              <Link to="/password/recovery">Password</Link>
            </li>
          </ul>
        );
      } else {
        menu = (
          <ul className="nav navbar-nav">
            <li>
              <Link to="/admin/organization">Organizations</Link>
            </li>
            <li>
              <Link to="/admin/researcher">Researcher</Link>
            </li>
            <li>
              <Link to="/admin/publication">
                Publications<span className="sr-only">(current)</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/journal">Journals</Link>
            </li>
            <li>
              <Link to="/admin/weight">Weights</Link>
            </li>
            <li>
              <Link to="/admin/export">Exports</Link>
            </li>
            <li>
              <Link to="/admin/settings">Settings</Link>
            </li>
            <li>
              <Link to="/password/recovery">Password</Link>
            </li>
          </ul>
        );
      }
    }

    const devWarning =
      window.location.toString().indexOf("dev") >= 0 ||
      window.location.toString().indexOf("127.0.0.1") >= 0 ? (
        <div
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "red",
            textAlign: "center",
          }}
        >
          Development version!!!
        </div>
      ) : null;

    return (
      <header>
        <nav className="navbar navbar-default">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              onClick={this.toggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <strong>Forschungsmonitoring</strong>
            </Link>
          </div>
          <div
            className={
              "container no-pad-sm" + (this.state.menu ? "" : " hidden")
            }
          >
            <div className="row no-pad-sm navbar-collapse">
              {menu}

              {path.substring(0, 6) !== "/login" && (
                <ul className="nav navbar-nav navbar-right">
                  {auth_loggedIn() ? (
                    <li>
                      <Link to="/login" onClick={this.logout}>
                        Logout
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </nav>
        {devWarning}
      </header>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Header;

/**
 * Created by thoenis on 02.07.2018.
 */
import React, { Component } from 'react';

class DataDisclaimer extends Component {

    render() {

        return <div className = "container mt-30 content ">
            <div className="col-lg-1"/>
            <div className="col-lg-10" style={{textAlign: "justify"}}>
                <h1>Datenschutzerkl&auml;rung Forschungsmonitoring</h1>
                <p>&nbsp;</p>
                <h2>Allgemein</h2>
                <p>
                    Die KOF Konjunkturforschungsstelle der ETH Z&uuml;rich (KOF) erstellt in Zusammenarbeit mit der
                    Heinrich-Heine-Universit&auml;t D&uuml;sseldorf (DICE) zusammen, alternierend jedes Jahr ein VWL
                    oder ein BWL Ranking. Diese Rankings sind in Personen und ein nicht personenbezogenes
                    Institutions- Rankings unterteilt.
                </p>
                <p>
                    Die <a href="http://www.kof.ethz.ch/">KOF Konjunkturforschungsstelle der ETH Z&uuml;rich</a> (KOF)
                    sammelt, verarbeitet und publiziert die Resultate auf <a
                    href="http://www.forschungsmonitoring.org">www.forschungsmonitoring.org</a> in erster Linie zu
                    Langzeit Forschungszwecken.
                </p>
                <p>
                    Das Forschungsmonitoring strebt nach Transparenz und Vertrauen, wenn es um den Schutz der
                    Privatsph&auml;re geht, und wir m&ouml;chten Ihnen deutlich machen wie wir Daten erfassen
                    und verarbeiten. Es ist uns wichtig das Sie wissen, dass Sie unsere Webseiten nutzen k&ouml;nnen,
                    ohne Ihre Privatsph&auml;re zu gef&auml;hrden. Diese Seite beschreibt, wie wir verschiedene Arten
                    von Informationen sammeln und verwenden, sowie die Gr&uuml;nde daf&uuml;r. Wenn Sie mehr
                    &uuml;ber die Rankings selbst erfahren m&ouml;chten, finden Sie auf der Webseite(
                    <a href="http://www.forschungsmonitoring.org">www.forschungsmonitoring.org</a>) unter jedem
                    Ranking die entsprechende Beschreibung oder allgemein in dem Beitrag auf der <a
                    href="http://www.oekonomenstimme.org/artikel/2017/06/handelsblatt-ranking-20-wissenschaftlicher-flexibler-transparenter/">&Ouml;konomenstimme.org</a>.
                </p>

                <h2>Informationen, die wir sammeln und wie wir die Daten verwenden</h2>
                <h3>A. <span>&nbsp;</span>Auf der Webseite <a href="http://www.forschungsmonitoring.org">www.forschungsmonitoring.org</a> als Besucher</h3>
                <p>
                    <strong>
                        Mit Ihrem Zugriff auf das Forschungsmonitoring werden standardm&auml;ssig Verbindungsdaten erfasst
                        und gespeichert
                    </strong>
                    , die Ihr Browser an uns &uuml;bermittelt. Wir erheben lediglich Zugriffsdaten in anonymisierter Form,
                    die uns keinen direkten R&uuml;ckschluss auf Ihre Person erm&ouml;glicht. Diese Daten werden von
                    Mitarbeitern der <a href="https://www.kof.ethz.ch/">KOF Konjunkturforschungsstelle der ETH Z&uuml;rich</a>
                    ausschliesslich f&uuml;r eigene statistische Zwecke ausgewertet, die uns helfen die Webseite zu
                    verbessern. Hierzu verwenden wir das Webanalyse-Tool &laquo;<strong>Google Analytics</strong>&raquo;.
                    Diese Daten werden bei den Betreibern der Webanalyse-Tools gespeichert.
                </p>
                <h4>Google Analytics</h4>
                <p>
                    Wir verwenden f&uuml;r unsere Website Google Analytics, einen Dienst der amerikanischen Google Inc.
                    (nachfolgend &laquo;Google&raquo;). Google Analytics verwendet unter anderem auch Cookies.
                    Cookies sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
                    der Webseite erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber die Benutzung
                    dieser Webseite werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort
                    gespeichert. &Uuml;bertragen wird auch Ihre IP-Adresse, die jedoch bei Google vor dem Speichern
                    anonymisiert wird, so dass sie Ihnen nicht mehr zugeordnet werden kann. Google verwendet hierzu die
                    Methode _anonymizeIp().
                </p>
                <p>
                    &Uuml;ber die Nutzung unserer Webseite k&ouml;nnen folgende Daten gespeichert sowie anonym und
                    ausschliesslich zu statistischen Zwecken ausgewertet werden:
                </p>
                <ul>
                    <li>Die besuchten Seiten und ihre Reihenfolge,</li>
                    <li>das verwendete Betriebssystem und der verwendete Browser,</li>
                    <li>Datum und Zeit der Seitenaufrufe,</li>
                    <li>die Adresse der zuvor betrachteten Webseite, falls Ihr Besuch &uuml;ber einen dort platzierten
                        direkten Link zu uns zustande gekommen ist und</li>
                    <li>Informationen &uuml;ber den Standort des Besuchers.</li>
                </ul>
                <p>
                    Google kann diese Liste erweitern und Informationen gegebenenfalls an Dritte &uuml;bertragen, sofern dies
                    gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.
                </p>
                <p>
                    Sie k&ouml;nnen die &Uuml;bertragung Ihrer Daten, sowie die Bearbeitung und Speicherung Ihrer Daten,
                    bei Google verhindern, indem Sie unter folgendem Weblink das entsprechende Browser-Erweiterung von
                    Google beziehen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                    https://tools.google.com/dlpage/gaoptout?hl=de</a> &nbsp;&nbsp;
                </p>
                <p>
                    F&uuml;r einen noch umfassenderen Schutz Ihrer Privatsph&auml;re wollen, k&ouml;nnen Sie auch
                    entsprechende Browser-Erweiterungen wie beispielsweise Ghostery verwenden.
                </p>
                <p>
                    Weitere Angaben zum Datenschutz bei Google finden Sie unter folgendem Weblink: <a
                    href="https://www.google.com/intl/de_DE/analytics/learn/privacy.html">https://www.google.com/intl/de_DE/analytics/learn/privacy.html</a>
                </p>
                <h4>Sicherheit</h4>
                <p>
                    Die Webseiten des Forschungsmonitorings k&ouml;nnen mit den g&auml;ngigen Browsern (z.B. Internet
                    Explorer, Firefox, Safari, Opera) aufgerufen werden.
                </p>
                <p>
                    F&uuml;r Registrierungen wird ein Sicherheitssystem f&uuml;r die &Uuml;bertragung und Speicherung aller
                    Informationen verwendet. Die Secure Sockets Layer-Technologie (SSL) verschl&uuml;sselt Ihren gesamten
                    Datenverkehr mit der Webseite. Wenn Sie mit einem sicherheitsf&auml;higen Browser arbeiten, kann Ihre
                    &Uuml;bertragung im Internet in der Regel nicht von Unbefugten gelesen werden. Alle heute &uuml;blichen
                    Browser unterst&uuml;tzen SSL. Es handelt sich um einen Weltweiten Standard.
                </p>
                <h4>Cookies</h4>
                <p>
                    Wir verwenden Cookies f&uuml;r unsere Webseiten. Cookies sind kleine Textdateien, die beim Besuch
                    unserer Website auf Ihrem Computer dauerhaft oder tempor&auml;r gespeichert werden. Zweck der Cookies
                    ist insbesondere die statistische Auswertung der Nutzung unserer Website. Dies dient der Verbesserung
                    unserer Webseite. Sie k&ouml;nnen Cookies auf unserer Website in Ihrem Browser jederzeit ganz oder
                    teilweise deaktivieren.
                </p>
                <h3>B. <span>&nbsp;</span>Die Rankings</h3>
                <p>
                    Die KOF Konjunkturforschungsstelle der ETH Z&uuml;rich (KOF) erstellt in Zusammenarbeit mit der
                    Heinrich-Heine-Universit&auml;t D&uuml;sseldorf (DICE) zusammen, alternierend jedes Jahr ein VWL oder
                    ein BWL Ranking. Diese Rankings sind in Personen und Institutions- Rankings unterteilt.
                </p>
                <h4>Erhebung der Daten</h4>
                <p>
                    Als Grundlage dienen die &ouml;ffentlich zug&auml;nglichen bibliographischen Daten und Sammlungen von
                    Bibliotheken, wie zum Beispiel die von RePec.org und auch von Institutionen welche die Bibliographischen
                    Daten bereitstellen, wie zum Beispiel der deutschen Zentralbibliothek ZBW oder EconLit.
                </p>
                <p>
                    Studentische Hilfskr&auml;fte der beiden Institutionen, KOF und DICE, vervollst&auml;ndigen die
                    fehlenden Angaben im Rahmen des zumutbarem, gem&auml;ssen an Ressourcen welches f&uuml;r das
                    Forschungsprojekt zur Verf&uuml;gung steht. Die Bearbeitung der Daten st&uuml;tzt sich auf
                    Art. 36c ff. ETH-Gesetz.
                </p>
                <h4>1. <span>&nbsp;</span>F&uuml;r die Autoren Rankings gesammelten Daten</h4>
                <p>
                    <strong>Alle Autoren, soweit die Email Adressen mitgeliefert wurden oder mit einem angemessenen Aufwand
                        erhoben werden k&ouml;nnen, werden angeschrieben,</strong> gem&auml;ss<strong>Art. 36e ff. ETH-Gesetz.
                    Um eigene Angaben selbst anzusehen, zu korrigieren oder um diese vervollst&auml;ndigen zu k&ouml;nnen
                    </strong>, braucht es eine Registrierung der Autoren auf <a href="http://www.forschungsmonitoring.org">
                    www.forschungsmonitoring.org</a>, dies geschieht &uuml;ber die Emailadresse <a
                    href="mailto:kontakt@forschungsmonitoring.org">kontakt@forschungsmonitoring.org</a>.
                    &Auml;nderungsw&uuml;nsche k&ouml;nnen auch direkt an <a
                    href="mailto:kontakt@forschungsmonitoring.org">kontakt@forschungsmonitoring.org</a> gesendet werden.
                    Alle Personen Rankings beschr&auml;nken sich auf deutschsprachige &Ouml;konomen, und die, die in diesen
                    L&auml;ndern t&auml;tig sind. Ber&uuml;cksichtig werden nur Beitr&auml;ge welche in Journals publiziert
                    wurden, die in der <a href="https://www.ebsco.com/products/research-databases/econlit">EconLit Datenbank
                    </a> zu finden sind.
                </p>
                <p>&nbsp;</p>
                <p>
                    F&uuml;r das <strong>&Ouml;konomen Ranking</strong> brauchen wir folgende Angaben um das Ranking zu berechnen:
                </p>
                <ul>
                    <li><strong>Vollst&auml;ndiger Name</strong></li>
                    <li><strong>Titel</strong></li>
                    <li><strong>Die Staatzugeh&ouml;rigkeit, falls die Personen nicht in Deutschland, der Schweiz oder
                        &Ouml;sterreich t&auml;tig sind, ansonsten das Institut an dem die Personen t&auml;tig sind in
                        Deutschland, der Schweiz oder &Ouml;sterreich</strong>
                    </li>
                    <li><strong>Die Publikationsliste der letzten 5 Jahre, ohne das aktuelle Jahr.</strong>
                        <ul>
                            <li><strong>Publikationstitel</strong></li>
                            <li><strong>Autoren und Co-Autoren</strong></li>
                            <li><strong>Journal in dem publiziert wurde</strong></li>
                            <li><strong>Das Jahr in dem publiziert wurde</strong></li>
                        </ul>
                    </li>
                </ul>
                <p>
                    Informativ werden zus&auml;tzlich auch <strong>JEL Codes, die Zugeh&ouml;rigkeit zur Institution</strong>
                    und die <strong>Anzahl Publikationen</strong> die ber&uuml;cksichtigt worden sind, im Ranking publiziert.
                    Sie dienen nur zur Einordnung und Information f&uuml;r den Besucher des Forschungsmonitoring.
                </p>
                <p>&nbsp;</p>
                <p>
                    Um <strong>zus&auml;tzlich</strong> f&uuml;r das &laquo;<strong>Young Economist Ranking</strong>&raquo;
                    ber&uuml;cksichtig zu werden, m&uuml;ssen die Personen 40 Jahre alt oder j&uuml;nger sein.
                    Dazu brauchen wir zus&auml;tzlich:
                </p>
                <ul>
                    <li><strong>Das Geburtsdatum</strong></li>
                </ul>
                <p>
                    <strong>Das Geburtsdatum m&uuml;ssen Sie selbst nach der Registrierung auf der Webseite des
                        Forschungsmonitoring angeben. Es kann jeweils aus den &ouml;ffentlichen verf&uuml;gbaren CVs von den
                        Institutswebseiten bezogen werden, soweit es mit einem angemessenen Aufwand m&ouml;glich ist.</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                    Um Personen f&uuml;r das &laquo;<strong>Lifetime Ranking</strong>&raquo; ber&uuml;cksichtigen zu
                    k&ouml;nnen, brauchen wir:
                </p>
                <ul>
                    <li><strong>Die vollst&auml;ndige Publikationsliste</strong>
                        <ul>
                            <li><strong>Publikationstitel</strong></li>
                            <li><strong>JEL Codes</strong></li>
                            <li><strong>Autoren und Co-Autoren</strong></li>
                            <li><strong>Journal in dem Publiziert wurde</strong></li>
                            <li><strong>Das Jahr in dem publiziert wurde</strong></li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    Wenn Sie namentlich <strong>nicht erw&auml;hnt werden wollen</strong> im &Ouml;konomen-, Young Economist
                    &ndash; oder Lifetime Ranking, oder in allen der Rankings, dann k&ouml;nnen Ihre Daten im Ranking
                    anonymisiert werden. In dem Fall werden Ihre Personendaten gel&ouml;scht, die Publikation wird mit dem
                    Bezug auf das Institut jedoch weiterhin gef&uuml;hrt. Bitte melden Sie sich dazu bei <a
                    href="mailto:kontakt@forschungsmonitoring.org">kontakt@forschungsmonitoring.org</a>.
                </p>
                <p>&nbsp;</p>
                <h4>2. <span>&nbsp;</span>F&uuml;r das Instituts &amp; Universit&auml;ts Ranking verwendete Daten</h4>
                <p>
                    <strong>Institute die sich nicht in Deutschland, der Schweiz oder &Ouml;sterreich befinden, werden nicht
                        ber&uuml;cksichtigt.</strong>
                </p>
                <h5>Strongest Professor</h5>
                <p>
                    In den Universit&auml;t und Instituts Rankings wird der <strong>Anteil und der Name des Professors</strong>
                    ausgewiesen, dessen berechneten Publikationspunkte den <strong>h&ouml;chsten Anteil am Gesamtergebnis</strong>
                    der jeweiligen Universit&auml;t oder des jeweiligen Instituts ausmacht. Falls Sie hier namentlich nicht
                    erw&auml;hnt werden wollen, bitte melden Sie sich dazu bei <a
                    href="mailto:kontakt@forschungsmonitoring.org">kontakt@forschungsmonitoring.org</a>. In dem Fall werden
                    Ihre Personendaten gel&ouml;scht, die Publikation wird mit dem Bezug auf das Institut jedoch weiterhin
                    gef&uuml;hrt und fliesst in das Ranking mit ein.
                </p>
                <p>
                    F&uuml;r die Universit&auml;t<strong> und Instituts Rankings</strong> brauchen wir folgende Angaben um
                    das Ranking zu berechnen:
                </p>
                <ul>
                    <li><strong>Name des Instituts</strong></li>
                    <li><strong>In welchem Land das Institut sich befindet (AT, CH oder DE)</strong></li>
                    <li><strong>Die Publikationsliste</strong> <strong>der letzten 5 Jahre aller an dieser Institution publizierten Journal Beitr&auml;ge, im Bereich der &Ouml;konomie, ohne das aktuelle Jahr.</strong>
                        <ul>
                            <li><strong>Publikationstitel</strong></li>
                            <li><strong>Autoren</strong></li>
                            <li><strong>Autoren Titel falls Professor</strong></li>
                            <li><strong>Journal in dem publiziert wurde</strong></li>
                            <li><strong>Das Jahr in dem publiziert wurde</strong></li>
                        </ul>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <h3>Aufbewahrung und Speicherung Ihrer Daten</h3>
                <p>
                    Wir speichern die Daten sicher in der Schweiz, innerhalb der Infrastruktur welche uns von der ETH
                    Z&uuml;rich zur Verf&uuml;gung gestellt wird, und bewahren diese Daten gesichert, zu
                    Langzeit-Forschungszwecken auf. Die Daten werden auf Gesuch hin und nach sp&auml;testens 20 Jahren
                    gel&ouml;scht, Art. 36d ff. ETH-Gesetz . Datenl&ouml;schungen gelten aus Gr&uuml;nden der
                    Forschungsintegrit&auml;t (Reproduzierbarkeit von Ergebnissen) nicht r&uuml;ckwirkend.
                </p>
                <h3>Publizierendes Organ</h3>
                <p>
                    Die Ergebnisse der Rankings werden auf <a href="http://www.forschungsmonitoring.org">
                    www.forschungsmonitoring.org</a> publiziert. Ausschliesslich berechnete und hochaggregierte Resultate
                    der Rankings werden auch auf anderen Plattformen, wie Zeitungen und Zeitschriften publiziert.
                </p>
                <h3>Recht auf Auskunft, Berichtigung, Sperrung und L&ouml;schung</h3>
                <p>
                    Sollten Sie eine Auskunft zu erhobenen Informationen oder eine Berichtigung, Vernichtung, Sperrung oder
                    L&ouml;schung dieser Daten w&uuml;nschen oder weitergehende Fragen zu deren Verwendung haben, wenden Sie
                    sich bitte an <a href="mailto:kontakt@forschungsmonitoring.org">kontakt@forschungsmonitoring.org</a>.
                </p>
                <p>&nbsp;</p>
                <h3>Datenschutzbeauftragte</h3>
                <p>
                    <h4>Datenschutzbeauftragter der ETH Z&uuml;rich (DPO):</h4>
                    <p>
                        <b>Herr Tomislav Mitar</b><br/>
                        Rämistrasse 101<br/>
                        CH-8092 Zürich<br/>
                        Tel. +41 44 632 21 21<br/>
                        Mail ds<span style={{display: "none"}}>null</span>@ethz.ch
                    </p>

                    <h4>Datenschutzbeauftragte der HHU Düsseldorf (DPO):</h4>
                    <p>
                        <b>Dr. Ursula Hilgers</b><br/>
                        Universitätsstr. 1<br/>Gebäude: 16.11<br/>Etage/Raum: 01.88<br/>
                        40225 Düsseldorf<br/>
                        Tel.: +49 211 81 13060<br/>
                        Mail datenschutz<span style={{display: "none"}}>null</span>@hhu.de
                    </p>
                </p>
                <p>&nbsp;</p>
                <div style={{fontSize: 12}}>
                    <p>Letzte Anpassung<br/>27.Juni 2018</p>
                </div>
            </div>
        </div>;
    }
}

export default DataDisclaimer;
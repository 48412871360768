import React, { Component } from 'react';
import { Link } from 'react-router';

class FontPagePanel extends Component {

    render() {

        var link = null;

        if(this.props.linkTarget) {
            link = <Link to={this.props.linkTarget} className="btn btn-primary pull-right">{this.props.linkText}</Link>;
        }

        return (<div className="col-xs-12 col-sm-6 col-md-offset-1 col-md-4">
            <div className="panel panel-primary">
                <div className="panel-heading">
                    <h3 className="panel-title">{this.props.title}</h3>
                </div>
                <div className="panel-body">
                    {this.props.children}
                    {link}
             </div>
            </div>
        </div>);
    }
}

export default FontPagePanel;
import React, { Component } from 'react';

class RankingComingSoon extends Component {

    render() {
        return (<div className="alert alert-info col-12">
            <h2>We are currently calculating this year's ranking. It will be made available soon.</h2>
            </div>);
    }

}

export default RankingComingSoon;
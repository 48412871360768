import React, { Component } from "react";
import window from "window-or-global";

import { ajax, ajax_get } from "../../../network/Ajax";

import JournalListBody from "./JournalListBody";
import Filter from "../../../filter/Filter";
import Message from "../../../message/Message";
import Pagination from "../../../filter/Pagination";
import { Spinner } from "../../../spinner/Spinner";

class JournalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: [],
      showDetails: {},
      filter: [],
      offset: 0,
      limit: 20,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (window.location.search) {
      this.loadData();
    }
  }

  loadData() {
    let success = (result) => {
      this.setState({
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        data: JSON.parse(result[0].data) || [],
        isLoading: false,
      });
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    var requestUrl =
      ApiUrl +
      "?na=active&ca==&va=true" +
      (this.state.query
        ? this.state.query
        : "&" + window.location.search.substring(1)) +
      (this.state.offset != 0 ? "&offset=" + this.state.offset : "");

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  }

  exportCSV() {
    var self = this;

    let success = (result) => {
      var downloadUrl =
        ApiUrl + "/export/webview/" + result.token + "?" + self.state.query;

      newTab.location = downloadUrl;
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
    };

    var newTab = window.open("", "_blank");

    var requestUrl = "/api/v1/account/tokens/download/request";

    ajax("POST", requestUrl, success, error);
  }

  search(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.loadData()
    );
  }

  export(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.exportCSV()
    );
  }

  setOffset(offset) {
    this.setState(
      {
        offset,
      },
      () => this.loadData()
    );
  }

  setLimit(limit) {
    this.setState({
      limit: limit,
    });
  }

  render() {
    return (
      <div id={Anchor}>
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <Filter
            formOptions={FormOptions}
            search={this.search.bind(this)}
            export={this.export.bind(this)}
            limit={this.state.limit}
            setLimit={this.setLimit.bind(this)}
            name="Journal"
          />
        </section>
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>BWL</th>
                    <th>VWL</th>
                    <th>Weight</th>
                    <th>Verified</th>
                    <th>ID</th>
                    <th></th>
                  </tr>
                </thead>
                <JournalListBody
                  data={this.state.data}
                  getOffset={this.state.offset}
                />
              </table>
            </div>
          </section>
        )}
        <Pagination
          data={this.state.data}
          limit={this.state.limit}
          getOffset={this.state.offset}
          setOffset={this.setOffset.bind(this)}
          anchor={Anchor}
        />
      </div>
    );
  }
}

const ApiUrl = "/api/v1/journals";
const Anchor = "journal-list";
const FormOptions = [
  {
    key: "title",
    val: "Title",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "ranking_relevant",
    val: "Ranking relevant",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "vwl_ranking_relevant",
    val: "VWL",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "bwl_ranking_relevant",
    val: "BWL",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "issn",
    val: "ISSN",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
  },
  {
    key: "journal_id",
    val: "ID",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["~*", "contains"],
    ],
  },
  {
    key: "verified",
    val: "Verified",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
];

export default JournalList;

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";

import Jquery from "jquery";

import { ajax, ajax_get } from "../../../../network/Ajax";
import Message from "../../../../message/Message";
import { Spinner } from "../../../../spinner/Spinner";
import AddJelCodes from "../../../../formcomponents/AddJelCodes";
import AddAuthors from "../../../../formcomponents/AddAuthors";
import JournalSearch from "../../../../search/JournalSearch";

function emptyFallbackData(data = {}) {
  return {
    title: data.title ?? "",
    active: data.active ?? true,
    publication_id: data.publication_id ?? "",
    publication_type: data.publication_type ?? "",
    publication_year: data.publication_year ?? "",
    journal_id: data.journal_id ?? "",
    journal_nr: data.journal_nr ?? "",
    journal_title: data.journal_title ?? "",
    page_start: data.page_start ?? "",
    page_end: data.page_end ?? "",
    volume: data.volume ?? "",
    in_process_to_publication: data.in_process_to_publication ?? false,
    verified: data.verified ?? false,
    jel_codes: data.jel_codes ?? [],
    authors: data.authors ?? [],
    researcher_ids: data.researcher_ids ?? [],
  };
}
/**
 * @param publicationType: Typ der Publikation
 * @param predefined: Predeined Elemente für <select>
 */
class PublicationEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: emptyFallbackData(),
      delete: false,
      showActive: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.params.id) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.id != prevProps.params.id) {
      this.loadData();
    }
  }

  loadData = () => {
    var success = (result) => {
      if (!result[0].data) {
        this.setState({
          response_code: "204",
          response_text:
            "No Publication with the ID " +
            this.props.params.id +
            " could be found.",
          data: emptyFallbackData(),
          isLoading: false,
        });
      } else {
        var data = JSON.parse(result[0].data)[0];

        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          data: emptyFallbackData(data || {}),
          showActive: !data.active,
          isLoading: false,
        });
      }
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    var requestUrl =
      "/api/v1/publications?k=publication_id&c==&v=" + this.props.params.id;

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  };

  toggleDelete = () => {
    this.setState({
      delete: !this.state.delete,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;

    const updatedValue =
      target.type === "checkbox" ? target.checked : target.value;
    this.setState((state) => ({
      data: {
        ...state.data,
        [target.name]: updatedValue,
      },
    }));
  };

  setLocation = (key, organization_id, organization_name) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        [key]: organization_id,
        [`${key}_name`]: organization_name,
      },
    }));
  };

  setJournal = (journalId, title) => {
    this.setState((state) => ({
      data: { ...state.data, journal_id: journalId, journal_title: title },
    }));
  };

  addJelCode = (code, label) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: [
          ...state.data.jel_codes,
          {
            jel_code: code,
            predefined_text_label: label,
          },
        ],
      },
    }));
  };

  removeJelCode = (code) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: state.data.jel_codes.filter(
          (jel) => jel && jel.jel_code != code
        ),
      },
    }));
  };

  addAuthor = (author) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        authors: [...state.data.authors, author],
      },
    }));
  };

  removeAuthor = (researcher_id) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        authors: state.data.authors.filter(
          (author) => author.researcher_id != researcher_id
        ),
      },
    }));
  };

  savePublication = () => {
    var publication = { ...this.state.data };

    var success = (result) => {
      console.log("result is", result);
      if (result[0].response_code != "0" || !result[0].data) {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        console.log("params is", this.props.params);
        if (!this.props.params.id) {
          var resultData = JSON.parse(result[0].data)[0];
          this.setState(
            (state) => ({
              isLoading: false,
              data: {
                ...state.data,
                publication_id: resultData.publication_id,
              },
            }),
            () => {
              this.context.router.push(
                "/admin/publication/editor/" + resultData.publication_id
              );
            }
          );
        } else {
          this.setState(
            {
              isLoading: false,
            },
            () =>
              this.context.router.push(
                "/admin/publication?n=publication_id&c==&v=" +
                  JSON.parse(result[0].data)[0].publication_id
              )
          );
        }
      }
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    publication.page_count =
      publication.page_end - publication.page_start || null;
    publication.researcher_ids = publication.authors.map(
      (author) => author.researcher_id
    );

    if (!this.props.params.id) {
      if (
        publication.title &&
        publication.authors[0] &&
        publication.publication_year &&
        publication.publication_type &&
        publication.journal_id
      ) {
        ajax("POST", "/api/v1/publications", success, error, publication);

        this.setState({
          isLoading: true,
          response_code: "0",
        });
      } else {
        this.setState({
          response_code: "400",
          response_text: "Please set all required fields!",
        });

        Jquery("html, body").animate(
          {
            scrollTop: 0,
          },
          500
        );
      }
    } else {
      if (
        publication.title &&
        publication.authors[0] &&
        publication.publication_year &&
        publication.publication_type &&
        publication.journal_id
      ) {
        ajax("PUT", "/api/v1/publications", success, error, publication);
      } else {
        this.setState({
          response_code: "400",
          response_text: "Please set all required fields!",
        });

        Jquery("html, body").animate(
          {
            scrollTop: 0,
          },
          500
        );
      }

      this.setState({
        isLoading: true,
        response_code: "0",
      });
    }
  };

  deletePublication = () => {
    var success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        this.context.router.push("/admin/publication");
        this.setState({
          isLoading: false,
        });
      }
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    ajax("DELETE", "/api/v1/publications", success, error, this.state.data);

    this.setState({
      isLoading: true,
    });
  };

  render() {
    var title = "Add a new Publication";
    var data = this.state.data;

    if (this.props.params.id) title = "Edit Publication";

    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <h1 className="col-sm-offset-3 col-sm-9">{title}</h1>
        </section>

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <form className="form-horizontal">
              <div className="form-group">
                <label htmlFor="titel" className="col-sm-3 control-label">
                  Title <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <input
                    value={data.title}
                    onChange={this.handleInputChange}
                    className="form-control"
                    id="titel"
                    name="title"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="publication_year"
                  className="col-sm-3 control-label"
                >
                  Year of publication <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <div className="col-xs-3 no-pl">
                    <input
                      value={data.publication_year}
                      onChange={this.handleInputChange}
                      className="form-control"
                      id="publication_year"
                      name="publication_year"
                      placeholder="Year"
                      type="number"
                    />
                  </div>
                </div>
                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <hr />
                </div>
              </div>

              <AddAuthors
                data={this.state.data}
                add={this.addAuthor}
                remove={this.removeAuthor}
              />

              <div className="form-group">
                <label
                  htmlFor="publication_year"
                  className="col-sm-3 control-label"
                >
                  Journal <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <JournalSearch
                    value={[data.journal_id, data.journal_title]}
                    onSelect={this.setJournal}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="journalIssue"
                  className="col-sm-3 control-label"
                >
                  Volume | Number
                </label>
                <div id="journalIssue" className="col-sm-9 col-md-6">
                  <div className="col-xs-3 no-pl">
                    <input
                      value={data.volume}
                      onChange={this.handleInputChange}
                      className="form-control"
                      name="volume"
                      placeholder="Volume"
                    />
                  </div>
                  <div className="col-xs-3">
                    <input
                      value={data.journal_nr}
                      onChange={this.handleInputChange}
                      className="form-control"
                      name="journal_nr"
                      placeholder="Number"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="pages" className="col-sm-3 control-label">
                  Pages
                </label>
                <div id="pages" className="col-sm-9 col-md-6">
                  <div className="col-xs-3 no-pl">
                    <input
                      value={data.page_start}
                      onChange={this.handleInputChange}
                      name="page_start"
                      type="number"
                      className="form-control"
                      placeholder="Start"
                    />
                  </div>
                  <div className="col-xs-3">
                    <input
                      value={data.page_end}
                      onChange={this.handleInputChange}
                      name="page_end"
                      type="number"
                      className="form-control"
                      placeholder="End"
                    />
                  </div>
                </div>
                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <hr />
                </div>
              </div>

              <div className="form-group">
                <label
                  htmlFor="in_process_to_publication"
                  className="col-sm-3 control-label"
                >
                  In process to publication
                </label>
                <div
                  id="in_process_to_publication"
                  className="col-sm-9 col-md-6"
                >
                  <label className="checkbox-inline">
                    <input
                      checked={this.state.data.in_process_to_publication}
                      onChange={this.handleInputChange}
                      type="checkbox"
                      name="in_process_to_publication"
                    />{" "}
                    Yes
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label
                  htmlFor="inputArtPublikation"
                  className="col-sm-3 control-label"
                >
                  Type of Publication <span className="red">*</span>
                </label>
                <div id="inputArtPublikation" className="col-sm-9 col-md-6">
                  <select
                    className="form-control"
                    name="publication_type"
                    value={data.publication_type}
                    onChange={this.handleInputChange}
                  >
                    <option disabled value="">
                      {" "}
                      -- Please select an Option --{" "}
                    </option>
                    {this.props.publicationType.map(function (value) {
                      return (
                        <option
                          key={value.publication_type_id}
                          value={value.publication_type_id}
                        >
                          {value.publication_type}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <AddJelCodes
                data={this.state.data}
                predefined={this.props.predefined}
                add={this.addJelCode}
                remove={this.removeJelCode}
                usedIn="publication"
              />

              <div className="form-group">
                <div className="checkbox col-sm-offset-3 col-sm-9">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.data.verified}
                      onChange={this.handleInputChange}
                      name="verified"
                    />{" "}
                    <strong>Verified</strong>
                  </label>
                </div>
              </div>

              {this.state.showActive && (
                <div className="form-group">
                  <div className="checkbox col-sm-offset-3 col-sm-9">
                    <label>
                      <input
                        type="checkbox"
                        checked={data.active}
                        onChange={this.handleInputChange}
                        name="active"
                      />{" "}
                      <strong>Active</strong>
                    </label>
                  </div>
                </div>
              )}
            </form>

            <div className="row">
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                <hr />
              </div>

              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                {this.props.params.id && (
                  <button
                    className="btn btn-danger"
                    onClick={this.toggleDelete}
                  >
                    Delete
                  </button>
                )}
                <div className="pull-right">
                  <Link to="/admin/publication" className="btn btn-default">
                    Dismiss
                  </Link>
                  <button
                    className="btn btn-primary ml-15"
                    onClick={this.savePublication}
                  >
                    Save
                  </button>
                </div>
              </div>

              {this.state.delete && (
                <div className="col-xs-12 col-sm-offset-3 col-sm-9 col-md-6 mt-30">
                  <div className="well">
                    <h4>Are you sure you want to delete this publication?</h4>
                    <button
                      className="btn btn-primary"
                      onClick={this.toggleDelete}
                    >
                      No
                    </button>
                    <button
                      className="btn btn-danger pull-right"
                      onClick={this.deletePublication}
                    >
                      Delete permanently
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

PublicationEditor.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default PublicationEditor;

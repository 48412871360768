import React, { Component } from "react";

import LocationSearch from "../search/LocationSearch";
import AddAffiliation from "./AddAffiliation";
import AddJelCodes from "./AddJelCodes";

/**
 * @param data: Daten wenn das Formular zum Update benutzt wird.
 * @param handleInputChange: Funktion welche die Änderunen in den Formularfeldern übernimmt.
 * @param setLocation: Funktion welche die Änderunen innerhalb der Ort-Suche übernimmt.
 * @param predefined: Die Vordefinierten Werte zur Auswahl in den Dropdowns
 * @param isExternal: Flag ob die Komponente (false) für Admin oder Accounts (true) verwendet wird
 * @param addJelCode: Hinzufügen eines Jel Codes im Client nach DB Update
 * @param removeJelCode: Remove an existing Jel Code
 * @param addAffiliation: Hinzufügen einer Affiliation im Client nach DB Update
 * @param updateAffiliation: Update an existing affiliation
 * @param removeAffiliation: Remove an existing affiliation
 */

class ExternalFormFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEmail: "",
      editEmail: false,
    };
  }

  editEmail = () => {
    this.setState({
      editEmail: true,
      currentEmail: this.props.data.email,
    });
  };

  dismissEditEmail = () => {
    this.props.handleInputChange({
      target: {
        value: this.state.currentEmail,
        name: "email",
      },
    });

    this.setState({
      editEmail: false,
    });
  };

  render() {
    var p = this.props;
    var data = p.data;
    var domain = "forschungsmonitoring.org";

    return (
      <div>
        <h3 className="col-sm-offset-3 col-sm-9 mb-15">Personal Information</h3>
        <div className="form-group">
          <label htmlFor="title" className="col-sm-3 control-label">
            Title <span className="red">*</span>
          </label>
          <div className="col-sm-9 col-md-6">
            <select
              id="title"
              className="form-control"
              name="title"
              value={data.title}
              onChange={p.handleInputChange}
            >
              <option disabled selected value>
                {" "}
                -- Please select a title --{" "}
              </option>
              {this.props.predefined
                .filter((elm) => elm.predefined_text_category_id == 2)
                .map((value) => (
                  <option
                    key={value.predefined_text_id}
                    value={value.predefined_text_id}
                  >
                    {value.predefined_text_label}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="name" className="col-sm-3 control-label">
            Name <span className="red">*</span>
          </label>
          <div id="name" className="col-sm-9 col-md-6">
            <div className="col-xs-6 no-pl">
              <input
                value={data.firstname}
                onChange={p.handleInputChange}
                id="firstname"
                name="firstname"
                className="form-control"
                placeholder="First Name"
              />
            </div>
            <div className="col-xs-6 no-pr">
              <input
                value={data.lastname}
                onChange={p.handleInputChange}
                id="lastname"
                name="lastname"
                className="form-control"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="orcid" className="col-sm-3 control-label">
            ORCID iD
          </label>
          <div id="orcid" className="col-sm-9 col-md-6">
            <input
              value={data.orcid}
              onChange={p.handleInputChange}
              id="orcid"
              name="orcid"
              className="form-control"
              placeholder="ORCID iD"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9 col-md-6">
            <hr />
          </div>
        </div>

        <AddAffiliation
          data={this.props.data}
          add={this.props.addAffiliation}
          update={this.props.updateAffiliation}
          remove={this.props.removeAffiliation}
          isExternal={this.props.isExternal}
          predefined={this.props.predefined}
        />

        <div className="form-group">
          <label htmlFor="birthdate" className="col-sm-3 control-label">
            Birthdate
          </label>
          <div className="col-sm-9 col-md-6">
            <input
              value={data.birthdate}
              onChange={p.handleInputChange}
              type="text"
              className="form-control"
              id="birthdate"
              name="birthdate"
              placeholder="YYYY-MM-DD"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="gender" className="col-sm-3 control-label">
            Gender
          </label>
          <div id="gender" className="col-sm-9 col-md-6">
            <select
              value={data.gender}
              onChange={p.handleInputChange}
              name="gender"
              className="form-control"
            >
              <option disabled selected value>
                {" "}
                -- Please select an Option --{" "}
              </option>
              <option value="F">female</option>
              <option value="M">male</option>
            </select>
          </div>
        </div>

        {!this.props.isExternal && (
          <div className="form-group">
            <label htmlFor="email" className="col-sm-3 control-label">
              Email{" "}
            </label>
            <div className="col-sm-9 col-md-6">
              <div
                className={
                  "no-pl " +
                  (data.researcher_id ? "col-xs-10" : "col-xs-12 no-pr")
                }
              >
                <input
                  value={data.email}
                  onChange={p.handleInputChange}
                  disabled={
                    data.researcher_id && data.email && !this.state.editEmail
                  }
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
              </div>
              {data.researcher_id && (
                <div>
                  <div className="col-xs-2 no-pr">
                    {!this.state.editEmail && (
                      <div
                        onClick={this.editEmail}
                        className="btn btn-primary pull-right"
                      >
                        Edit
                      </div>
                    )}
                    {this.state.editEmail && (
                      <div
                        onClick={this.dismissEditEmail}
                        className="btn btn-default pull-right"
                      >
                        Dismiss
                      </div>
                    )}
                  </div>
                  {this.state.editEmail && (
                    <div className="red">
                      After changing the email, the researcher needs to be
                      informed to set a new password via
                      https://forschungsmonitoring.org/password/recovery
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="cv_url" className="col-sm-3 control-label">
            CV
          </label>
          <div className="col-sm-9 col-md-6">
            <input
              value={data.cv_url}
              onChange={p.handleInputChange}
              type="url"
              className="form-control"
              id="cv_url"
              name="cv_url"
              placeholder="Link to your CV"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="website" className="col-sm-3 control-label">
            Website
          </label>
          <div className="col-sm-9 col-md-6">
            <input
              value={data.website}
              onChange={p.handleInputChange}
              type="url"
              className="form-control"
              id="website"
              name="website"
              placeholder="Link to your website"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="comments" className="col-sm-3 control-label">
            Comment
          </label>
          <div className="col-sm-9 col-md-6">
            <textarea
              value={data.comments}
              onChange={p.handleInputChange}
              type="text"
              className="form-control"
              id="comments"
              name="comments"
              rows="5"
              placeholder="Personal Comment"
            ></textarea>
          </div>
        </div>

        <div className="col-sm-offset-3 col-sm-9 col-md-6">
          <hr />
        </div>

        <h3 className="col-sm-offset-3 col-sm-9 mb-15">Research</h3>

        <div className="form-group">
          <label htmlFor="field" className="col-sm-3 control-label">
            Field <span className="red">*</span>
          </label>
          <div id="field" className="col-sm-9 col-md-6">
            <select
              value={data.field}
              onChange={p.handleInputChange}
              name="field"
              className="form-control"
            >
              <option disabled selected value>
                {" "}
                -- Please select an Option --{" "}
              </option>
              <option value="BWL">BWL</option>
              <option value="VWL">VWL</option>
            </select>
          </div>
          <div className="col-sm-offset-3 col-sm-9 col-md-6">
            <hr />
          </div>
        </div>

        <AddJelCodes
          data={data}
          predefined={this.props.predefined}
          add={this.props.addJelCode}
          remove={this.props.removeJelCode}
          usedIn="researcher"
          isExternal={this.props.isExternal}
        />

        <div className="form-group">
          <label htmlFor="habilitation" className="col-sm-3 control-label">
            Habilitation
          </label>
          <div id="habilitation" className="col-sm-9 col-md-6">
            <LocationSearch
              onSelect={p.setLocation}
              fieldName="habilitation_location"
              value={[
                data.habilitation_location,
                data.habilitation_location_name,
              ]}
            />
            <div className="col-xs-3 no-pr">
              <input
                value={data.habilitation_year}
                onChange={p.handleInputChange}
                id="habilitation_year"
                name="habilitation_year"
                type="number"
                className="form-control"
                placeholder="Year"
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="phd" className="col-sm-3 control-label">
            PHD
          </label>
          <div id="phd" className="col-sm-9 col-md-6">
            <LocationSearch
              onSelect={p.setLocation}
              fieldName="phd_location"
              value={[data.phd_location, data.phd_location_name]}
            />
            <div className="col-xs-3 no-pr">
              <input
                value={data.phd_year}
                onChange={p.handleInputChange}
                id="phd_year"
                name="phd_year"
                type="number"
                className="form-control"
                placeholder="Year"
              />
            </div>
          </div>
        </div>

        <div className="col-sm-offset-3 col-sm-9 col-md-6">
          <a
            className="show pt-15 grey"
            href={
              "mailto:kontakt" +
              "@" +
              domain +
              "?subject=Organization%20Request"
            }
          >
            Are you missing an Organization? Ask us via Email.
          </a>
        </div>
      </div>
    );
  }
}

export default ExternalFormFields;

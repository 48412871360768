import _ from 'lodash';
import window from 'window-or-global';

const JWT_STORAGE_KEY = 'forschungsmonitoring.kof.ethz.ch-jwt-key';
const JWT_STORAGE_SET_AT = 'forschungsmonitoring.kof.ethz.ch-jwt-setat';
const SESSION_LENGTH = 1000*60*60;

function set(token) {

    window.localStorage.setItem(JWT_STORAGE_KEY, token);
    window.localStorage.setItem(JWT_STORAGE_SET_AT, Date.now());
}

function isSet() {

    if(!_.isEmpty(window.localStorage.getItem(JWT_STORAGE_KEY))) {
        if(Date.now() - window.localStorage.getItem(JWT_STORAGE_SET_AT) > SESSION_LENGTH) {
            deleteToken();
            return false;
        } else {
            // Could extend session here, JWT only lasts 60 min though so no.
            return true;
        }
    } else {
        return false;
    }
}

function get() {

    return window.localStorage.getItem(JWT_STORAGE_KEY);
}

function deleteToken() {

    window.localStorage.removeItem(JWT_STORAGE_KEY);
}

export { set as jwt_set, isSet as jwt_isSet, get as jwt_get, deleteToken as jwt_delete };
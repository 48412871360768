import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";

import { auth_login } from "../../../security/Authentication";
import { Spinner } from "../../../spinner/Spinner";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { email: "", password: "", loading: false, isLoading: false };

    this.setLogin = this.setLogin.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }

  setLogin(event) {
    var newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  submitLogin(e) {
    e.preventDefault();

    var dispatch = function (auth_loggedIn, role, error, status) {
      if (!auth_loggedIn) {
        this.setState({ email: "", password: "" });

        this.setState({
          isLoading: false,
          fomoError: error,
          authRequestStatus: status,
        });
      } else {
        if (role == "fm_admin") {
          this.context.router.push("/admin/researcher");
        } else if (role == "fm_user") {
          this.context.router.push("/profile");
        }
      }
    }.bind(this);

    this.setState({
      isLoading: true,
      fomoError: null,
    });

    auth_login(this.state.email, this.state.password, dispatch);
  }

  render() {
    return (
      <div className="container content">
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <div className="well mt-30">
            {this.state.fomoError === "profile_not_in_db" ? (
              <div className="row bg-danger">
                <h4 className="col-sm-offset-4 col-sm-5 mb-30">
                  Login successful but profile not found.
                </h4>
                <div className="col-sm-offset-4 col-sm-5 mb-30">
                  Please contact{" "}
                  <a href="mailto:kontakt@forschungsmonitoring.org">
                    kontakt@forschungsmonitoring.org
                  </a>{" "}
                  for help.
                </div>
              </div>
            ) : null}

            {this.state.authRequestStatus === 401 ? (
              <div className="row bg-info">
                <h4 className="col-sm-offset-4 col-sm-5 mb-30">Login failed</h4>
                <div className="col-sm-offset-4 col-sm-5 mb-30">
                  Invalid username or password.
                </div>
              </div>
            ) : null}

            <div className="row">
              <h4 className="col-sm-offset-4 col-sm-5 mb-30">
                Login to your account:
              </h4>
            </div>

            <form className="form-horizontal">
              <div className="form-group">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-2 col-sm-offset-2 control-label"
                >
                  Email
                </label>
                <div className="col-sm-5">
                  <input
                    value={this.state.email}
                    onChange={this.setLogin}
                    name="email"
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="inputPassword3"
                  className="col-sm-2 col-sm-offset-2 control-label"
                >
                  Password
                </label>
                <div className="col-sm-5">
                  <input
                    value={this.state.password}
                    onChange={this.setLogin}
                    name="password"
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    placeholder="Passwort"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-5">
                  <button
                    onClick={this.submitLogin}
                    className="btn btn-default pull-right"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
            <div className="row">
              <p className="col-sm-offset-4 col-sm-5 mb-15">
                Forgot your password?{" "}
                <Link to="/password/recovery">
                  Follow this link to request a new Password.
                </Link>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Login;

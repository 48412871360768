import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import Moment from "moment";

import { ajax_get } from "../../../network/Ajax";
import Message from "../../../message/Message";
import { Spinner } from "../../../spinner/Spinner";
import JELGeneralNotice from "../../../notices/JELGeneralNotice";

const RequestUrl = "/api/v1/profil";

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    this.loadData();
  }

  loadData = (query) => {
    let success = (result) => {
      this.setState({
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        data: JSON.parse(result[0].data) || [],
        isLoading: false,
      });
    };

    let error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    this.setState({
      isLoading: true,
    });

    ajax_get(RequestUrl, success, error);
  };

  render() {
    var d = this.state.data[0];

    return (
      <div className="container content">
        <section className="row">
          <Link to="/profile/editor" className="pull-right">
            <button className="btn btn-primary">Edit Profile</button>
          </Link>
        </section>

        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section>
            <h1>
              {d.title_name} {d.firstname} {d.lastname}
            </h1>
            <hr />

            <dl className="dl-horizontal">
              <dt>Affiliations:</dt>
              <dd>
                <ul className="list-unstyled">
                  {d.affiliations
                    .sort(function (a, b) {
                      //to_date's can also contain the string 'Today'
                      var startA = a.to_date;
                      var startB = b.to_date;

                      if (startA == "Today") {
                        startA = new Date();
                      }
                      if (startB == "Today") {
                        startB = new Date();
                      }

                      return new Date(startB) - new Date(startA);
                    })
                    .map(function (value) {
                      if (value) {
                        return (
                          <li className="mb-7">
                            <span className="kof-color">
                              {value.organization_name}
                            </span>
                            {value.employment && (
                              <span> | {value.employment} %</span>
                            )}
                            <br />
                            {value.from_date && (
                              <span>
                                {Moment(value.from_date).format("Do MMMM YYYY")}
                              </span>
                            )}
                            {value.to_date && value.to_date != "Today" && (
                              <span>
                                {" "}
                                - {Moment(value.to_date).format("Do MMMM YYYY")}
                              </span>
                            )}
                            <br />
                            {value.title_name && (
                              <span>{value.title_name} | </span>
                            )}
                            {<span>{value.description}</span>}
                          </li>
                        );
                      }
                    })}
                </ul>
              </dd>
              <hr />
              <dt>Field:</dt>
              <dd>{d.field}</dd>
              <dt>JEL Codes:</dt>
              <dd>
                <ul className="list-unstyled">
                  {d.jel_codes.map(function (d) {
                    return <li>{d ? d.jel_code_name : null}</li>;
                  })}
                </ul>
                {d.field == "VWL" ? <JELGeneralNotice /> : null}
              </dd>
              <hr />
              <dt>Gender:</dt>
              <dd>{d.gender}</dd>
              <dt>Birthday:</dt>
              <dd>
                {d.birthdate && Moment(d.birthdate).format("Do MMMM YYYY")}
              </dd>
              <hr />
              <dt>Email:</dt>
              <dd>{d.email}</dd>
              <dt>Website:</dt>
              <dd>
                <a href={d.website}>{d.website}</a>
              </dd>
              <dt>CV:</dt>
              <dd>
                <a href={d.cv_url}>{d.cv_url}</a>
              </dd>
              <hr />
              <dt>Habilitation:</dt>
              <dd>
                {d.habilitation_location_name} - {d.habilitation_year}
              </dd>
              <dt>PHD:</dt>
              <dd>
                {d.phd_location_name} - {d.phd_year}
              </dd>
              <hr />

              <dt>Comment:</dt>
              <dd>{d.comments}</dd>

              <hr />

              <dt>ID:</dt>
              <dd>{d.researcher_id}</dd>

              <dt>ORCID iD</dt>
              <dd>{d.orcid}</dd>
            </dl>
          </section>
        )}
      </div>
    );
  }
}

ProfilePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ProfilePage;

import React, { Component } from "react";

import { ajax } from "../network/Ajax";
import { Spinner } from "../spinner/Spinner";
import AuthorSearch from "../search/AuthorSearch";

/**
 * @param data: Daten wenn das Formular zum Update benutzt wird.
 * @param add: Function to add an author
 * @param remove: Function to remove an author
 * @param isExternal: Flage welche zeigt ob die Komponente für interne Admins oder externe Accounts genutzt wird (optional)
 */

class AddAuthors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      temp: {},
      data: {},
      authorUrl: this.props.isExternal
        ? "/api/v1/publications/author/byresearcher"
        : "/api/v1/publications/author",
      isLoading: false,
    };
  }

  setAuthor = (author) => {
    if (this.props.data.publication_id) {
      this.addAuthor(author);
    } else {
      this.props.add(author);
    }
  };

  addAuthor = (author) => {
    var self = this;

    var success = (result) => {
      if (result[0].response_code == "0") {
        self.props.add(author);
      }

      self.setState({
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      self.setState({
        isLoading: false,
      });
    };

    var exists = false;

    for (var i = 0; i < this.props.data.authors.length; i++) {
      if (this.props.data.authors[i].researcher_id == author.researcher_id) {
        exists = true;
        break;
      }
    }

    if (!exists) {
      ajax("POST", this.state.authorUrl, success, error, {
        publication_id: this.props.data.publication_id,
        researcher_ids: [author.researcher_id],
      });

      self.setState({
        isLoading: true,
      });
    }
  };

  removeAuthor = (researcher_id) => {
    var self = this;

    if (this.props.data.publication_id) {
      var success = () => {
        self.props.remove(researcher_id);
        self.setState({
          isLoading: false,
        });
      };

      var error = (xhr, status, err) => {
        console.error(status, err.toString(), xhr.toString);
        self.setState({
          isLoading: false,
        });
      };

      ajax("DELETE", this.state.authorUrl, success, error, {
        publication_id: this.props.data.publication_id,
        researcher_ids: [researcher_id],
      });

      self.setState({
        isLoading: true,
      });
    } else {
      self.props.remove(researcher_id);
    }
  };

  render() {
    var self = this;
    var data = this.props.data;
    var domain = "forschungsmonitoring.org";

    return (
      <div className="form-group">
        <label htmlFor="field" className="col-sm-3 control-label">
          Authors <span className="red">*</span>
        </label>
        <div id="field" className="col-sm-9 col-md-6">
          {this.state.isLoading ? <Spinner /> : null}

          {data.authors && (
            <ul className="list-unstyled row">
              {this.props.data.authors
                .sort(function (a, b) {
                  return a.lastname.localeCompare(b.lastname);
                })
                .map(function (value) {
                  return value ? (
                    <li className="col-xs-12 mb-7" key={value.researcher_id}>
                      <span className="label label-default pull-left">
                        {value.firstname + " " + value.lastname}
                      </span>
                      <span
                        className="label label-danger pull-right pointer"
                        onClick={self.removeAuthor.bind(
                          this,
                          value.researcher_id
                        )}
                      >
                        x
                      </span>
                    </li>
                  ) : null;
                })}
            </ul>
          )}
          {this.props.data.publication_id && (
            <h6>
              (Changes to the Authors are stored immediately without clicking
              save.)
            </h6>
          )}

          <AuthorSearch onSelect={this.setAuthor} />
        </div>
        <div className="col-sm-offset-3 col-sm-9 col-md-6">
          <a
            className="show pt-15 grey"
            href={"mailto:kontakt" + "@" + domain + "?subject=Author%20Request"}
          >
            Are you missing an Author? Ask us via Email.
          </a>
          <hr />
        </div>
      </div>
    );
  }
}

export default AddAuthors;

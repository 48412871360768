import React, { Component } from "react";

import { setPredefined as fetchPredefined } from "../predefined/Predefined";
import Header from "./header/Header";
import Footer from "./footer/Footer";

class MonitoringApp extends Component {
  constructor(props) {
    super(props);

    this.state = { predefined: [], publicationType: [] };

    this.set = this.set.bind(this);
    this.update = this.update.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    fetchPredefined(this.set);
  }

  set(data) {
    const parsed = JSON.parse(data.predefined_text[0].data);

    this.setState({
      predefined: parsed,
      publicationType: JSON.parse(data.publication_type[0].data),
    });
  }

  update(element) {
    this.setState((state) => ({
      predefined: state.predefined.map((item) =>
        item.predefined_text_id == element.predefined_text_id ? element : item
      ),
    }));
  }

  add(element) {
    this.setState((state) => ({
      predefined: [...state.predefined, element],
    }));
  }

  remove(element) {
    this.setState((state) => ({
      predefined: state.predefined.filter(
        (item) => item.predefined_text_id != element.predefined_text_id
      ),
    }));
  }

  render() {
    return (
      <div>
        <Header />
        {React.cloneElement(this.props.children, {
          predefined: this.state.predefined,
          publicationType: this.state.publicationType,
          updatePredefined: this.update,
          addPredefined: this.add,
          removePredefined: this.remove,
        })}
        <Footer loggedIn={true} />
      </div>
    );
  }
}

export default MonitoringApp;

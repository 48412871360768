import React, { Component } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/slider";
import "jquery-ui/ui/widgets/tooltip";
import "jquery-ui/themes/base/all.css";

import DataTable from "datatables.net";
import "datatables.net-responsive";

class RankingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vintage: null,
    };

    this.initTable = this.initTable.bind(this);
    this.setSource = this.setSource.bind(this);
  }

  initTable(source) {
    var weightingScheme = this.props.weightingScheme;
    var rankingType = this.props.rankingType;

    var queryParams = {
      ranking_vintage: this.props.vintage, // Make selectable/default to current year
      ranking_scheme: weightingScheme,
      ranking_category: rankingType,
    };

    var table = new DataTable(this.refs.rankingtable, {
      ajax: source + "?" + $.param(queryParams),
      columns: this.props.columns,
      paging: false,
      autowidth: true,
      responsive: true,
      processing: true,
      dom: "rt", // Just the table please, nothing fancy
      language: {
        processing: "<img src= '/spinner.gif'/>", // TODO: This will fail if not hosted under root. PUBLIC_PATH needs to be used (somehow...)
      },
      initComplete: function (settings, json) {
        $("#rankingvintage").html("Last update: " + json.data[0].vintage);

        // datatables initializes its header in the last tr of thead
        // This means when switching between rankings the "roles" of the ths switch
        // and we need to juggle them around a bit
        var filterHeader = $("#rankingtable thead tr").eq(0);
        var titleHeader = $("#rankingtable thead tr").eq(1);
        titleHeader.after(filterHeader);
        filterHeader.attr("id", "");
        titleHeader.attr("id", "titleHeader");
        filterHeader.attr("id", "filterHeader");
        filterHeader.show();

        table.columns().every(function (i) {
          var col = this;
          var filterCell = filterHeader.find("th").eq(i);
          filterCell
            .empty()
            .removeClass("sorting_asc")
            .removeClass("sorting_desc")
            .removeClass("sorting");
          if (col.nodes().to$().hasClass("fomo_filterable")) {
            filterCell.append(
              $(
                "<div><input type='text' class='columnFilterInput form-control' placeholder='Filter...'/></div>"
              ).on("keyup", function (e) {
                var tis = $(e.target);
                if (e.key.search("Esc") >= 0) {
                  tis.val("");
                }
                var v = tis.val();
                col.search(v).draw();
                tis.blur().focus(); // Can't we just bury MS browsers and never talk about them again?
              })
            );
            titleHeader
              .find("th")
              .eq(i)
              .append($("<div class='colWidthHack110'></div>"));
          } else if (col.nodes().to$().hasClass("fomo_age")) {
            var ages = col.data().unique().sort(); // This probably breaks if there are 1/3 digit ages, but see below
            var minAge = parseInt(ages[0] == "" ? ages[1] : ages[0]);
            var maxAge = parseInt(ages[ages.length - 1]);

            var ageHeader = $(col.header());
            if (minAge < maxAge) {
              ageHeader.html(
                ageHeader.html() +
                  " <span class='ageRangeDisplay'>" +
                  minAge +
                  "-" +
                  maxAge +
                  "</span>"
              );
              filterCell.append(
                $("<div id='ageSlider'></div>").slider({
                  range: true,
                  min: minAge,
                  max: maxAge,
                  values: [minAge, maxAge],
                  change: function (event, ui) {
                    var regex = "";
                    var s = $("#ageSlider");
                    if (
                      ui.values[0] == s.slider("option", "min") &&
                      ui.values[1] == s.slider("option", "max")
                    ) {
                      regex = ".*";
                    } else {
                      // Let's be ageist and assume everybody has a two digit age. ;P
                      var lowerTen = Math.floor(ui.values[0] / 10);
                      var higherTen = Math.floor(ui.values[1] / 10);
                      var minOne = ui.values[0] % 10;
                      var maxOne = ui.values[1] % 10;
                      var lowerOne = Math.min(minOne, maxOne);
                      var higherOne = Math.max(minOne, maxOne);
                      if (lowerTen == higherTen) {
                        regex =
                          "[" +
                          lowerTen +
                          "][" +
                          lowerOne +
                          "-" +
                          higherOne +
                          "]";
                      } else if (higherTen - lowerTen >= 2) {
                        regex =
                          "([" +
                          lowerTen +
                          "][" +
                          minOne +
                          "-9]|[" +
                          (lowerTen + 1) +
                          "-" +
                          (higherTen - 1) +
                          "][0-9]" +
                          "|[" +
                          higherTen +
                          "][0-" +
                          maxOne +
                          "])";
                      } else {
                        regex =
                          "([" +
                          lowerTen +
                          "][" +
                          minOne +
                          "-9]|[" +
                          higherTen +
                          "][0-" +
                          maxOne +
                          "])";
                      }
                    }
                    col.search(regex, true).draw();
                  },
                  slide: function (event, ui) {
                    var ageHeader = $(col.header());
                    ageHeader
                      .children(".ageRangeDisplay")
                      .html(ui.values[0] + "-" + ui.values[1]);
                  },
                })
              );
              titleHeader
                .find("th")
                .eq(i)
                .append($("<div class='colWidthHack110'></div>"));
            }
          } else if (col.nodes().to$().hasClass("fomo_country")) {
            filterCell.append(
              $(
                "<select class='form-control columnFilterInput'>" +
                  "<option>all</option>" +
                  "<option>AT</option>" +
                  "<option>CH</option>" +
                  "<option>DE</option>" +
                  "<option>DACH</option>" +
                  "<option>other</option>" +
                  "</select>"
              ).on("change", function (e) {
                var selected = e.target.selectedIndex;
                var regex = ".*";
                switch (selected) {
                  case 1:
                    regex = "AT";
                    break;
                  case 2:
                    regex = "CH";
                    break;
                  case 3:
                    regex = "DE";
                    break;
                  case 4:
                    regex = "CH|DE|AT";
                    break;
                  case 5:
                    regex = "^(?!CH$|AT$|DE$|N/A$).*";
                    break;
                }
                col.search(regex, true).draw();
              })
            );
            titleHeader
              .find("th")
              .eq(i)
              .append($("<div class='colWidthHack70'></div>"));
          }
        });

        $("#rankingtable [title]").tooltip({
          show: { duration: 100, delay: 50 },
          hide: { duration: 0 },
        });
      },
    }).on("responsive-resize.dt", function (e, datatable, columns) {
      for (var i in columns) {
        $("#filterHeader th").eq(i).toggle(columns[i]);
      }
    });
    this.table = table;
  }

  setSource(url, rankField, pointsField) {
    var queryParams = {
      ranking_vintage: this.props.vintage, // Make selectable/default to current year
      ranking_scheme: pointsField,
    };

    this.table.ajax.url(url + "?" + $.param(queryParams)).load(function (json) {
      var ages = [];
      for (var i = 0; json && i < json.data.length; i++) {
        if (ages.indexOf(json.data[i].age) < 0) {
          ages.push(json.data[i].age);
        }
      }
      ages.sort();
      var minAge = parseInt(ages[0] == "" ? ages[1] : ages[0]);
      var maxAge = parseInt(ages[ages.length - 1]);
      $(".ageRangeDisplay").html(minAge + "-" + maxAge);
      var slider = $("#ageSlider").slider();
      slider.slider("option", "min", minAge);
      slider.slider("option", "max", maxAge);
      slider.slider("option", "values", [minAge, maxAge]);
      /*$("#ageRangeSlider").slider({
       min: minAge,
       max: maxAge,
       values: [minAge, maxAge]
       });*/
    });
  }

  componentDidMount() {
    this.initTable(this.props.source);
  }

  destroyTable() {
    $("#titleHeader").hide();
    new DataTable(this.refs.rankingtable).destroy();
  }

  componentWillUnmount() {
    this.destroyTable();
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.weightingScheme != this.props.weightingScheme ||
      nextProps.source != this.props.source
    ) {
      this.setSource(
        nextProps.source,
        nextProps.rankField,
        nextProps.weightingScheme
      );
    }

    if (nextProps.disclaimerAmount != this.props.disclaimerAmount) {
      if (nextProps.disclaimerAmount) {
        $(".disclaimer").html(
          "Only the publications of the first " +
            nextProps.disclaimerAmount +
            " authors are verified."
        );
      } else {
        $(".disclaimer").html("");
      }
    }

    // No React allowed in here!
    return false;
  }

  render() {
    var tr = [];
    for (var i = 0; i < this.props.columns.length; i++) {
      tr.push(<th key={i}></th>);
    }
    return (
      <div>
        <table
          id="rankingtable"
          ref="rankingtable"
          className="display stripe compact"
        >
          <thead>
            <tr id="filterHeader" hidden>
              {tr}
            </tr>
            <tr id="titleHeader">{tr}</tr>
          </thead>
        </table>
        <div id="rankingvintage"></div>
        {/* this.props.disclaimerAmount ? (<div className="disclaimer">
             Only the publications of the first {this.props.disclaimerAmount} authors are verified.
             </div>) : null */}
      </div>
    );
  }
}

export default RankingTable;

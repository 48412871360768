import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";

import { ajax, ajax_get } from "../../../../network/Ajax";
import Message from "../../../../message/Message";
import { Spinner } from "../../../../spinner/Spinner";
import ExternalFormFields from "../../../../formcomponents/ExternalFormFields";

/**
 * @param predefined: Predefined Werte für <select>
 */
class ProfileEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    let success = (result) => {
      if (!result[0].data) {
        this.setState({
          response_code: "204",
          response_text: "Es konnte kein Profil gefunden werden.",
          data: {},
          isLoading: false,
        });
      } else {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          data: JSON.parse(result[0].data)[0] || {},
          isLoading: false,
        });
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    var requestUrl = "/api/v1/profil";

    ajax_get(requestUrl, success, error);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const updatedValue =
      target.type === "checkbox" ? target.checked : target.value;

    this.setState((state) => ({
      data: {
        ...state.data,
        [target.name]: updatedValue,
      },
    }));
  };

  setLocation = (key, organization_id, organization_name) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        [key]: organization_id,
        [`${key}_name`]: organization_name,
      },
    }));
  };

  addAffiliation = (affiliation) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        affiliations: [...state.data.affiliations, affiliation],
      },
    }));
  };

  updateAffiliation = (updatedAffiliation) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        affiliations: state.data.affiliations.map((affiliation) =>
          affiliation.affiliation_id == updatedAffiliation.affiliation_id
            ? updatedAffiliation
            : affiliation
        ),
      },
    }));
  };

  removeAffiliation = (affiliation_id) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        affiliations: state.data.affiliations.filter(
          (affiliation) => affiliation.affiliation_id != affiliation_id
        ),
      },
    }));
  };

  addJelCode = (code, label) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: [
          ...state.data.jel_codes,
          { jel_code: code, predefined_text_label: label },
        ],
      },
    }));
  };

  removeJelCode = (jel_code) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        jel_codes: state.data.jel_codes.filter(
          (jel) => jel && jel.jel_code != jel_code
        ),
      },
    }));
  };

  saveResearcher = () => {
    let success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
        });
      } else {
        if (!this.state.data.researcher_id) {
          var resultData = JSON.parse(result[0].data)[0];
          this.setState((state) => ({
            data: {
              ...state.data,
              researcher_id: resultData.researcher_id,
            },
            isLoading: false,
          }));
          this.context.router.push(
            `/profile/editor/${resultData.researcher_id}`
          );
        } else {
          this.context.router.push("/profile");
          this.setState({
            isLoading: false,
          });
        }
      }
    };

    let error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isloading: false,
      });
    };

    var Method = this.state.data.researcher_id ? "PUT" : "POST";

    this.setState({
      isLoading: true,
    });

    ajax(Method, "/api/v1/profil", success, error, this.state.data);
  };

  render() {
    var data = this.state.data;
    var title = data.researcher_id ? "Edit Profile" : "Create Profile";

    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section>
            <div className="row">
              <h1 className="col-sm-offset-3 col-sm-9">{title}</h1>
            </div>

            <div className="row">
              <form className="form-horizontal">
                <ExternalFormFields
                  data={this.state.data}
                  handleInputChange={this.handleInputChange}
                  setLocation={this.setLocation}
                  predefined={this.props.predefined}
                  addAffiliation={this.addAffiliation}
                  updateAffiliation={this.updateAffiliation}
                  removeAffiliation={this.removeAffiliation}
                  addJelCode={this.addJelCode}
                  removeJelCode={this.removeJelCode}
                  isExternal={true}
                />
              </form>

              <div className="row">
                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <hr />
                </div>

                <div className="col-sm-offset-3 col-sm-9 col-md-6">
                  <div className="pull-right">
                    <Link to="/profile" className="btn btn-default">
                      Dismiss
                    </Link>
                    <button
                      className="btn btn-primary ml-15"
                      onClick={this.saveResearcher}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

ProfileEditor.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ProfileEditor;

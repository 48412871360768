import React, { Component } from "react";

import Header from "./header/Header";
import Footer from "./footer/Footer";

class PublicPages extends Component {
  render() {
    return (
      <div>
        <Header public={true} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default PublicPages;

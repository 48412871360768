import React, { Component } from "react";

import { ajax_get } from "../network/Ajax";

/**
 * @param value: Array [journal_id, name des Journals]
 * @param onSelect: Funktion die Ausgelöst wird sobald der Nutzer ein Suchresultat gewählt hat
 */

class JournalSearch extends Component {
  constructor(props) {
    super(props);

    this.state = { options: [] };
    this.timer = null;
  }

  search = (event) => {
    var self = this;

    this.props.onSelect(null, event.target.value);

    let success = (result) => {
      self.setState({
        options: JSON.parse(result[0].data) || [],
      });
    };

    clearTimeout(this.timer);

    this.timer = setTimeout(function () {
      var uri =
        "/api/v1/journals/search?k=title&c=~*&v=" +
        encodeURIComponent(self.props.value[1]) +
        "&limit=10";

      ajax_get(uri, success);
    }, 100);
  };

  select = (event) => {
    var index = event.nativeEvent.target.selectedIndex;
    var journal_id = event.target.value;
    var name = event.nativeEvent.target[index].text;

    this.setState({
      options: [],
    });

    this.props.onSelect(journal_id, name);
  };

  close = () => {
    this.setState({
      options: [],
    });
  };

  render() {
    var self = this;
    var domain = "forschungsmonitoring.org";
    var selector = (
      <select className="form-control" size="7" onChange={self.select}>
        <optgroup label="Bitte wählen Sie ein Suchergebnis:">
          {this.state.options.map(function (value, index) {
            return <option value={value.journal_id}>{value.title}</option>;
          })}
        </optgroup>
      </select>
    );

    return (
      <div className="has-feedback">
        <input
          value={this.props.value[1]}
          onChange={this.search}
          onClick={this.close}
          autoComplete="off"
          className="form-control"
          placeholder="Search Journal"
        />
        {this.props.value[0] ? (
          <span
            className="glyphicon glyphicon-ok form-control-feedback"
            aria-hidden="true"
          ></span>
        ) : null}
        {this.state.options[0] ? selector : null}
        <a
          className="show pt-15 grey"
          href={"mailto:kontakt" + "@" + domain + "?subject=Journal%20Request"}
        >
          Are you missing a Journal? Ask us via Email.
        </a>
      </div>
    );
  }
}

export default JournalSearch;

/**
 * @param predefined: Vordefinierte Werte, verwendet in <select>
 */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Link } from "react-router";

import Jquery from "jquery";

import { ajax, ajax_get } from "../../../../network/Ajax";
import Message from "../../../../message/Message";
import { Spinner } from "../../../../spinner/Spinner";

class OrganizationEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: {},
      delete: false,
      isLoading: false,
    };

    this.loadData = this.loadData.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveOrganization = this.saveOrganization.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id) {
      this.loadData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.id) {
      this.loadData();
    }
  }

  loadData(query) {
    var success = (result) => {
      if (!result[0].data) {
        this.setState({
          response_code: "204",
          response_text:
            "Es konnte keine Organisation mit der ID " +
            this.props.params.id +
            " gefunden werden.",
          data: [],
          isLoading: false,
        });
      } else {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          data: JSON.parse(result[0].data)[0] || [],
          isLoading: false,
        });
      }
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    var requestUrl =
      "/api/v1/organizations?na=active&ca==&va=true&k=organization_id&c==&v=" +
      this.props.params.id;

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  }

  toggleDelete() {
    this.setState({
      delete: !this.state.delete,
    });
  }

  handleInputChange(event) {
    var target = event.target;
    this.state.data[target.name] =
      target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      data: this.state.data,
    });
  }

  saveOrganization() {
    var success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        this.context.router.push(
          "/admin/organization?n=organization_id&c==&v=" +
            JSON.parse(result[0].data)[0].organization_id
        );
      }
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    if (this.state.data.name) {
      ajax("POST", "/api/v1/organizations", success, error, this.state.data);

      this.setState({
        isLoading: true,
        response_code: "0",
      });
    } else {
      this.setState({
        response_code: "400",
        response_text: "Please set all required fields!",
      });

      Jquery("html, body").animate(
        {
          scrollTop: 0,
        },
        500
      );
    }
  }

  deleteOrganization() {
    var success = (result) => {
      if (result[0].response_code != "0") {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
          isLoading: false,
        });
      } else {
        this.context.router.push("/admin/organization");

        this.setState({
          isLoading: false,
        });
      }
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    ajax(
      "DELETE",
      "/api/v1/organizations/" + this.state.data.organization_id,
      success,
      error,
      this.state.data
    );
    this.setState({
      isLoading: true,
    });
  }

  render() {
    var title = "Add a new organization";
    if (this.props.params.id) title = "Edit organization";

    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <h1 className="col-sm-offset-3 col-sm-9">{title}</h1>
        </section>

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <form className="form-horizontal">
              <div className="form-group">
                <label for="name" className="col-sm-3 control-label">
                  Name <span className="red">*</span>
                </label>
                <div className="col-sm-9 col-md-6">
                  <input
                    onChange={this.handleInputChange}
                    value={this.state.data.name}
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    required="true"
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="zipcode" className="col-sm-3 control-label">
                  Zip Code
                </label>
                <div className="col-sm-9 col-md-6">
                  <input
                    onChange={this.handleInputChange}
                    value={this.state.data.zipcode}
                    type="number"
                    className="form-control"
                    id="zipcode"
                    name="zipcode"
                    placeholder="Zip Code"
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="country_code" className="col-sm-3 control-label">
                  Country
                </label>
                <div className="col-sm-9 col-md-6">
                  <select
                    id="country_code"
                    className="form-control"
                    name="country_code"
                    value={this.state.data.country_code}
                    onChange={this.handleInputChange}
                  >
                    <option disabled selected value>
                      {" "}
                      -- Please select an option --{" "}
                    </option>
                    {Jquery.grep(this.props.predefined, function (elm) {
                      return elm.predefined_text_category_id == 6;
                    }).map(function (value, index) {
                      return (
                        <option value={value.predefined_text_id}>
                          {value.predefined_text_label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label for="description" className="col-sm-3 control-label">
                  Description
                </label>
                <div className="col-sm-9 col-md-6">
                  <textarea
                    onChange={this.handleInputChange}
                    value={this.state.data.description}
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="Description of the Organization"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div className="form-group">
                <div className="checkbox col-sm-offset-3 col-sm-9">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.data.verified}
                      onChange={this.handleInputChange}
                      name="verified"
                    />{" "}
                    <strong>Verified</strong>
                  </label>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                <hr />
              </div>
              <div className="col-sm-offset-3 col-sm-9 col-md-6">
                {this.props.params.id && (
                  <button
                    className="btn btn-danger"
                    onClick={this.toggleDelete}
                  >
                    Delete
                  </button>
                )}
                <div className="pull-right">
                  <Link to="/admin/organization" className="btn btn-default">
                    Dismiss
                  </Link>
                  <button
                    className="btn btn-primary ml-15"
                    onClick={this.saveOrganization}
                  >
                    Save
                  </button>
                </div>
              </div>
              {this.state.delete && (
                <div className="col-xs-12 col-sm-offset-3 col-sm-9 col-md-6 mt-30">
                  <div className="well">
                    <h4>Are you sure you want to delete this organization?</h4>
                    <button
                      className="btn btn-primary"
                      onClick={this.toggleDelete}
                    >
                      No
                    </button>
                    <button
                      className="btn btn-danger pull-right"
                      onClick={this.deleteOrganization}
                    >
                      Delete permanently
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

OrganizationEditor.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default OrganizationEditor;

import { ajax } from "../network/Ajax";
import { jwt_delete, jwt_set, jwt_isSet, jwt_get } from "./JWTStore";

function login(userName, password, callback) {
  // Make sure we get the right argument: login is also called without params.
  callback = arguments[arguments.length - 1];

  var updateAuthStateFn = (result) => {
    if (result.authenticated) {
      jwt_set(result.token);

      if (callback) {
        callback(true, result.role, null, result.status);
      }
      onAuthChange(true);
    } else {
      if (callback) {
        callback(false, null, result.error, result.status);
      }
      onAuthChange(false);
    }
  };

  // attempt to log in.
  authRequest(userName, password, updateAuthStateFn);
}

function getToken() {
  return jwt_get();
}

function logout(cb) {
  onAuthChange(false);
  cb();
}

function loggedIn() {
  return jwt_isSet();
}

function onAuthChange(loggedIn) {
  if (!loggedIn) {
    jwt_delete();
  }
}

function authRequest(userName, password, updateAuthStateFn) {
  let success = (data, status, xhr) => {
    updateAuthStateFn({
      authenticated: true,
      token: data.token,
      role: data.roles[0],
      status: xhr.status,
    });
  };

  var error = (xhr, status, err) => {
    updateAuthStateFn({
      authenticated: false,
      error:
        xhr.responseJSON &&
        xhr.responseJSON[0] &&
        xhr.responseJSON[0].fomo_error,
      status: xhr.status,
    });
    console.error(xhr);
    console.error(status);
    console.error(err.toString());
  };

  ajax("POST", "/api/v1/account/login", success, error, {
    uid: userName,
    pwd: password,
  });
}

function requireAuth(nextState, replace) {
  if (!loggedIn()) {
    replace({
      pathname: "/login",
      state: { nextPathname: nextState.location.pathname },
    });
  }
}

export {
  loggedIn as auth_loggedIn,
  logout as auth_logout,
  login as auth_login,
  requireAuth,
};

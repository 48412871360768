import React, { Component } from "react";
import { Link } from "react-router";

import JournalList from "./JournalList";

class JournalPage extends Component {
  render() {
    return (
      <div className="container content">
        <section className="row">
          <h1>Journal</h1>
          <Link to="/admin/journal/editor" className="pull-right">
            <button className="btn btn-primary">Add a new Journal</button>
          </Link>
        </section>
        <JournalList />
      </div>
    );
  }
}

export default JournalPage;

import React, { Component } from "react";

import { ajax } from "../../../network/Ajax";

/**
 * @param data: Daten des predefined elements
 * @param update: Update an existing predefined element
 * @param remove: Remove an existing predefined
 */
class PredefinedElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tempValue: "",
      predefined: this.props.data,
    };
  }

  handleInputChange = (event) => {
    const predefined_text_label = event.target.value;
    this.setState((state) => ({
      predefined: { ...state.predefined, predefined_text_label },
    }));
  };

  save = () => {
    let success = (result) => {
      this.setState({ newPredefined: "" });
      this.props.update(JSON.parse(result[0].data)[0]);
    };

    ajax("POST", "/api/v1/predefined", success, null, {
      predefined_text_id: this.state.predefined.predefined_text_id,
      predefined_text_category_id:
        this.state.predefined.predefined_text_category_id,
      predefined_text_label: this.state.predefined.predefined_text_label,
    });
  };

  remove() {
    var success = () => {
      this.setState({ newPredefined: "" });
      this.props.remove(this.state.predefined);
    };

    ajax(
      "DELETE",
      "/api/v1/predefined/" + this.state.predefined.predefined_text_id,
      success,
      null
    );
  }

  render() {
    return (
      <div className="form-group">
        <div className="col-sm-8 col-md-offset-3 col-md-6">
          <input
            onChange={this.handleInputChange}
            value={this.state.predefined.predefined_text_label}
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            required={true}
          />
        </div>
        <div className="col-sm-2 col-md-1 btn btn-primary" onClick={this.save}>
          Save
        </div>
        <div
          className="col-sm-2 col-md-1 ml-15 btn btn-danger"
          onClick={this.remove.bind(this)}
        >
          Delete
        </div>
      </div>
    );
  }
}

export default PredefinedElement;
